<template>
    <container-panel :no-card-body-padding-top="true">
        <div slot="title">评估内容</div>
        <custom-button
            v-show="canAdd"
            slot="button"
            v-auth="ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE"
            text="添加评估内容"
            type="reset"
            @click="handleAddOrEdit({})" />
        <!-- 表格 start -->
        <template slot="content" slot-scope="">
            <a-table :columns="columns"
                     :data-source="dataList"
                     :pagination="pagination"
                     :loading="loading"
                     row-key="id"
                     @change="handleTableChange">
                <template slot="index" slot-scope="text,action,index">
                    {{ +index + 1 }}
                </template>
                <!-- 操作 -->
                <template
                    slot="action"
                    slot-scope="text,action">
                    <a-button v-auth="RULE_ENGINE_RISK_LOOK_ASSESSMENT_CONTENT_BUTTON"
                              type="link"
                              @click="handleAddOrEdit(action,'look')">
                        查看
                    </a-button>
                    <template v-if="canAction">
                        <a-button v-auth="RULE_ENGINE_RISK_EDIT_ASSESSMENT_CONTENT_BUTTON"
                                  type="link"
                                  @click="handleAddOrEdit(action)">
                            编辑
                        </a-button>
                        <a-button v-auth="RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_BUTTON"
                                  type="link"
                                  @click="handleDelete(action)">
                            移除
                        </a-button>
                    </template>
                </template>
            </a-table>
        </template>
    <!-- 表格 end -->
    </container-panel>
</template>

<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_BUTTON,
    RULE_ENGINE_RISK_EDIT_ASSESSMENT_CONTENT_BUTTON,
    RULE_ENGINE_RISK_LOOK_ASSESSMENT_CONTENT_BUTTON
} from '@constant/authEnum/ruleEngine'
import {
    ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE
} from '@constant/menu_code'
import CustomButton from '@components/Button'

import getRiskAssessmentModelContentListService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentModelContentListService'
import deleteRiskAssessmentModelContentService
from '@service/ruleEngine/riskAssessmentModel/deleteRiskAssessmentModelContentService'
import { ERROR_COMMIT_DELETE } from '@constant/error'
import showModelButton from '@utils/showModelButton'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        key: 'index',
        scopedSlots: {
            customRender: 'index'
        }
    },
    {
        title: '布局分类',
        dataIndex: 'layoutName',
        align: 'left',
        key: 'layoutName'
    },
    {
        title: '风险对象',
        dataIndex: 'masterDataName',
        align: 'left',
        key: 'masterDataName'
    },
    {
        title: '风险分类',
        dataIndex: 'masterDataClassificationName',
        align: 'left',
        key: 'masterDataClassificationName'
    },
    {
        title: '评估内容',
        dataIndex: 'factorName',
        align: 'left',
        key: 'factorName'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskFactorTabPanel',
    components: {
        CustomButton,
        ContainerPanel,
        ATable,
        AButton
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            columns,
            params: {},
            dataList: [],
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_BUTTON,
            RULE_ENGINE_RISK_EDIT_ASSESSMENT_CONTENT_BUTTON,
            RULE_ENGINE_RISK_LOOK_ASSESSMENT_CONTENT_BUTTON,
            ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE
        }
    },
    computed: {
        pagination() {
            const { total, size, current } = this
            return {
                showTotal: (total, range) => `第${range[0]}-${range[1]}条/总共${total}条`,
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        queryId() {
            return this.$route.query.id
        },
        // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        },
        // 可添加
        canAdd() {
            const { info } = this
            return showModelButton.canAdd(info.auditState)
        },
        pushRouteName() {
            const { name } = this.$route
            let result = ''
            // 风险评估详情
            if (name === 'RiskAssessmentInfo') {
                result = 'AddOrEditRiskAssessmentContent'
            } else if (name === 'RuleApproveListInfo' || name === 'ApproveRiskAssessmentInfo') { // 规则审核 或者商品审核
                result = 'RuleApproveRiskAssessmentContent'
            }
            return result
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleAddOrEdit({ id }, isLook) {
            id = id || ''
            const { queryId, pushRouteName } = this
            this.$router.push({
                name: pushRouteName,
                query: {
                    queryId,
                    id,
                    look: isLook ? 1 : 0
                }
            })
        },
        handleDelete({ id }) {
            this.$confirm({
                title: '确认移除当前评估内容吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(id)
                },
                onCancel() {
                }
            })
        },
        async deleteService(id) {
            try {
                await deleteRiskAssessmentModelContentService(id)
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, size, queryId } = this
                const res = await getRiskAssessmentModelContentListService({
                    current,
                    size,
                    riskAssessmentModelId: queryId
                })
                this.dataList = res.records
                this.total = res.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">

</style>
