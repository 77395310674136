<!-- 风险评估意见概览 -->
<template>
    <table-panel class="risk-assessment-page-panel">
        <div slot="title">风险评估意见概览</div>
        <a-table
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="executionCountList"
            row-key="modelType" />
    </table-panel>
</template>

<script>
import TablePanel from '@components/TablePanel'
import { Table as ATable } from 'ant-design-vue'
import enumOperate from '@mixins/enumOperate'
import { COMMODITY_CONFIG_MAP } from '@constant/enum'

const columns = [
    {
        title: '序号',
        key: 'sort',
        align: 'left',
        width: 200,
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '风控模型',
        dataIndex: 'modelType',
        align: 'left',
        key: 'modelType',
        customRender: val => COMMODITY_CONFIG_MAP[val].name
    },
    {
        title: '风险评估意见',
        dataIndex: 'evaluationOpinion',
        align: 'left',
        key: 'evaluationOpinion',
        customRender: v => v || '暂无风险评分评级信息'
    },
    {
        title: '更新时间',
        dataIndex: 'modifyDateTime',
        align: 'left',
        key: 'modifyDateTime'
    }
]
export default {
    name: 'RiskAssessment',
    components: {
        TablePanel,
        ATable
    },
    mixins: [enumOperate],
    props: {
        executionCountList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            COMMODITY_CONFIG_MAP,
            columns,
            loading: false,
            data: [
                {
                    id: '1',
                    modelType: '黑名单',
                    riskAccess: '建议承保/不建议承保',
                    updateTime: '2021-02-02 12:23:00'
                }
            ]
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
</style>
