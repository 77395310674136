<!-- 规则集 公共组件 -->
<template>
    <section>
        <a-table
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="dataList"
            row-key="id">
            <template slot="ruleSetName" slot-scope="val,record">
                {{ `${val}规则集不符合条数${record.inconformityNum}条` }}
                <a-button
                    type="link"
                    style="margin-left: 20px"
                    @click="handleLook(record)">
                    查看
                </a-button>
            </template>
        </a-table>

        <base-rule-modal
            :visible="visible"
            :info="info"
            @cancel="visible=false"
        />
    </section>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import initTable from '@mixins/initTable'

const columns = [
    {
        title: '序号',
        scopedSlots: {
            customRender: 'sort'
        },
        key: 'sort',
        align: 'left',
        width: 200,
        customRender: (v, r, i) => ++i
    }, {
        title: '保司规则',
        dataIndex: 'ruleSetName',
        align: 'left',
        key: 'ruleSetName',
        scopedSlots: {
            customRender: 'ruleSetName'
        }
    }, {
        title: '更新时间',
        dataIndex: 'createDateTime',
        align: 'left',
        key: 'createDateTime'
    }
]
export default {
    name: 'RuleSetTable',
    components: {
        AButton,
        BaseRuleModal: () => import('./BaseRuleModal')
    },
    mixins: [initTable],
    props: {
        getListService: {
            type: Function
        }
    },
    data() {
        return {
            columns,
            loading: false,
            visible: false,
            info: {}
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    methods: {
        handleLook(info) {
            this.visible = true
            this.info = info
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, riskControlNumber } = this
                const param = {
                    current,
                    size: limit,
                    riskControlNumber
                }
                const data = await this.getListService(param) || {}
                this.dataList = data.records || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
