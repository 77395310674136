<!-- 选择风险等级
多选 需要根据风险等级模型切换数据 -->
<template>
    <select-option-label-prop
        v-model="modelValue"
        ref="selectOption"
        :filter-option="false"
        :select-options-data="dataList"
        placeholder="请选择" />
</template>

<script>
import getSelectRiskClassListService from '@service/ruleEngine/riskClass/getSelectRiskClassListService'

export default {
    name: 'RiskClassSelect',
    components: {
        SelectOptionLabelProp: () => import('@weights/SelectOptionLabelProp')
    },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        riskScoreModelId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {
        riskScoreModelId: {
            handler(val) {
                if (!val) return
                this.getList()
            },
            immediate: true
        }
    },
    methods: {
        async getList() {
            const { riskScoreModelId } = this
            try {
                this.dataList = await getSelectRiskClassListService({
                    riskScoreModelId
                })
                console.log(this.dataList)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
