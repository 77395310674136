<template>
    <custom-modal
        title="操作"
        :visible="visible"
        :mask-closable="false"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" @click="handleOk">
                提交
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 16 }"
            :model="form"
            :rules="rules">
            <a-form-model-item label="删除原因" prop="reason">
                <a-input v-model.trim="form.reason" :max-length="INPUT_MAX_LENGTH" placeholder="请输入" />
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput,
    Button as AButton
} from 'ant-design-vue'

import { INPUT_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'ConfirmDelete',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        AButton,
        CustomModal: () => import('@components/CustomModal')
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                reason: ''
            },
            rules: {
                reason: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入理由'
                    }
                ]
            }
        }
    },
    watch: {
        visible(v) {
            if (v) {
                this.form = {}
            }
        }
    },
    methods: {
        handleCancel() {
            this.$emit('close')
        },
        handleOk() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.$emit('ok', this.form)
                }
            })
        }
    }
}
</script>

<style lang='scss'>

</style>
