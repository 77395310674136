<!-- 风险提示 -->
<template>
    <table-panel
        class="risk-warning-page-panel">
        <div slot="title">
            风险提示
            <span class="risk-warning-page-panel__number">（{{ dataList.length }}条）</span>
        </div>
        <a-table
            :columns="columns"
            :pagination="pagination"
            :loading="loading"
            :data-source="dataList"
            row-key="id"
            @change="handleTableChange">
            <template slot="sort" slot-scope="text, record,index">{{ index+1 }}</template>
            <template slot="businessDataNumber" slot-scope="text">{{ text||'暂无风险提示信息' }}</template>
            <template
                slot="businessDataNumber"
                slot-scope="text,action">
                {{ action.businessObjectName }}{{ text }}条
                <a-button
                    v-if="text"
                    v-auth="ORDER_MANAGE_ORDER_INFO_ORDER_DETAIL_INFO_RISK_WARNING_VIEW_BUTTON"
                    type="link"
                    @click="handleViewButtonClick(action)"
                >
                    查看
                </a-button>
            </template>
        </a-table>
        <risk-warning-modal
            :id="id"
            :visible="visible"
            :data="currentInfo"
            @close-model="handleCloseModal" />
    </table-panel>
</template>

<script>
import TablePanel from '@components/TablePanel'
import { Table as ATable, Button as AButton } from 'ant-design-vue'
import RiskWarningModal from './RiskWarningModal'

import { ORDER_MANAGE_ORDER_INFO_ORDER_DETAIL_INFO_RISK_WARNING_VIEW_BUTTON } from '@constant/authEnum/order'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'
import getRiskWarningListService from '@service/task/getRiskWarningListService'

const columns = [
    {
        title: '序号',
        scopedSlots: {
            customRender: 'sort'
        },
        align: 'left',
        width: 200
    }, {
        title: '风险提示信息',
        dataIndex: 'businessDataNumber',
        align: 'left',
        key: 'businessDataNumber',
        scopedSlots: {
            customRender: 'businessDataNumber'
        }
    }, {
        title: '更新时间',
        dataIndex: 'executionTime',
        align: 'left',
        key: 'executionTime'
    // width: 150
    }
]
export default {
    name: 'RiskAssessmentPagePanel',
    components: {
        TablePanel,
        ATable,
        AButton,
        RiskWarningModal
    },
    mixins: [tableShowTotal],
    data() {
        return {
            columns,
            dataList: [],
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            ORDER_MANAGE_ORDER_INFO_ORDER_DETAIL_INFO_RISK_WARNING_VIEW_BUTTON,
            visible: false, // 弹框可见性
            currentInfo: [], // 当前弹框数据
            id: ''
        }
    },
    computed: {
        pagination() {
            const { total, size, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { size, current, riskControlNumber } = this
                const res = await getRiskWarningListService({
                    size,
                    current,
                    riskControlNumber
                }) || {}
                this.dataList = res.records || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
     * 点击查看按钮
     */
        handleViewButtonClick({ id }) {
            this.id = id
            this.visible = true
        },
        /**
     * 关闭弹框
     */
        handleCloseModal() {
            this.visible = false
        }
    }
}
</script>

<style lang="scss">
.risk-warning-page-panel {
  &__number {
    color: #f00;
  }
}
</style>
