<!-- 承保 - 保单表单 -->
<template>
    <a-card
        class="insurance-policy-form-panel"
        :title="`保单${index + 1}`"
        :bordered="false"
    >
        <template slot="extra">
            <!-- 删除保单 -->
            <a-popconfirm
                v-if="showRemoveButton"
                title="是否放弃本次修改?"
                ok-text="是"
                cancel-text="否"
                :disabled="!isEdited"
                @confirm="handleConfirmRemove"
            >
                <a-button type="link" @click="handleRemove">
                    删除保单
                </a-button>
            </a-popconfirm>
        </template>

        <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
        >
            <!-- 保单号 -->
            <a-form-model-item label="保单号" prop="insuranceId">
                <a-input
                    v-model="form.insuranceId"
                    placeholder="请输入保单号"
                />
            </a-form-model-item>

            <!-- 保费（元） -->
            <a-form-model-item label="保费（元）" prop="insurancePremium">
                <a-input-number
                    v-model="form.insurancePremium"
                    placeholder="请输入保费（元）"
                    style="width: 100%"
                />
            </a-form-model-item>

            <!-- 保险期限 -->
            <a-form-model-item label="保险期限" prop="dateRange">
                <a-range-picker
                    v-model="form.dateRange"
                    format="YYYY-MM-DD"
                    style="width: 100%"
                />
            </a-form-model-item>

            <!-- 保单上传 -->
            <a-form-model-item label="保单上传" prop="policyDocument">
                <upload-in-order
                    v-model="form.policyDocument"
                    @input="handleUploadInOrderInput"
                />
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>
<script>
// 组件
import {
    Button,
    Card,
    FormModel,
    Popconfirm,
    Input,
    InputNumber,
    DatePicker
} from 'ant-design-vue'
import UploadInOrder from '@weights/AppUpload/UploadInOrder'

import { getFileName } from '@utils/file'

export default {
    name: 'InsurancePolicyFormPanel',
    components: {
        UploadInOrder,
        AButton: Button,
        ACard: Card,
        AFormModel: FormModel,
        AFormModelItem: FormModel.Item,
        APopconfirm: Popconfirm,
        AInput: Input,
        AInputNumber: InputNumber,
        ARangePicker: DatePicker.RangePicker
    },
    props: {
        index: Number,
        showRemoveButton: Boolean
    },
    data() {
        const uploadValidator = (rule, value, callback) => {
            const { fullField } = rule

            const { documentAddr, documentName } = value

            if (!documentAddr || !documentName) {
                callback(Error(`${fullField} is required`))
            }

            callback()
        }

        return {
            // 表单数据
            form: {
                policyDocument: {}
            },
            // 表单验证规则
            rules: {
                insuranceId: [
                    {
                        required: true,
                        message: '请输入保单号'
                    }
                ],
                insurancePremium: [
                    {
                        required: true,
                        message: '请输入保费（元）'
                    }
                ],
                dateRange: [
                    {
                        required: true,
                        message: '请填写保险期限'
                    }
                ],
                policyDocument: [
                    {
                        required: true
                    }, {
                        validator: uploadValidator,
                        message: '请上传保单上传',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        isEdited() {
            const {
                insuranceId,
                insurancePremium,
                dateRange = [],
                policyDocument: { documentAddr = '', documentName = '' } = {}
            } = this.form

            return (
                insuranceId ||
                insurancePremium ||
                dateRange.length > 0 ||
                documentAddr ||
                documentName
            )
        }
    },
    methods: {
        // handle upload-in-order input event
        handleUploadInOrderInput() {
            const {
                policyDocument: {
                    documentAddr,
                    documentName
                }
            } = this.form

            if (documentAddr && documentName) {
                this.clearValidate('policyDocument')
            }
        },
        // 处理 删除保单按钮 点击事件
        handleRemove() {
            if (this.isEdited) {
                return
            }
            this.handleConfirmRemove()
        },
        // 处理 删除popconfirm 确认事件
        handleConfirmRemove() {
            this.$emit('on-remove', this.index)
        },
        clearValidate(props) {
            this.$refs.form.clearValidate(props)
        },
        validate() {
            const {
                insuranceId = '',
                policyDocument: {
                    documentName = ''
                }
            } = this.form

            if (insuranceId.trim() === getFileName(documentName)) {
                return this.$refs.form.validate()
            } else {
                this.$message.warning('保单文件名称应与保单号一致！')
                return Promise.reject('保单文件名称应与保单号一致！')
            }
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.insurance-policy-form-panel {
    .ant-card-head,
    .ant-card-head-title,
    .ant-card-extra,
    .ant-card-body {
        padding: 0
    }

    .ant-card-head {
        width: 500px;
        min-height: unset;
        border-top: 1px solid $component-border-color;
        border-bottom: none;
        padding: 10px 0 20px 0;
        .ant-card-head-title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
            line-height: 20px;
        }

        .ant-card-extra {
            .ant-btn {
                padding: 0;
            }
        }
    }
}
</style>
