<!-- 操作日志 -->
<template>
    <container-panel>
        <div slot="title">操作日志</div>
        <div class="operation-log-info-panel">
            <a-timeline
                v-if="dataList.length"
                class="operation-log-info-panel__timeline">
                <a-timeline-item
                    v-for="item in dataList"
                    :key="item.id">
                    <p class="timeline-time">{{ item.createDateTime }}</p>
                    <p class="operation-log-info-panel__title">{{ parseTitle(item) }}</p>
                    <div v-for="(descItem,index) in JSON.parse(item.compareJson)" :key="index" class="operation-log-info-panel__remark">
                        <span>{{ descItem.name }}：</span>
                        <a-button v-if="showListButton(descItem.value)" size="small" @click="handleLookLogList(descItem)">点击查看</a-button>
                        <span v-else>{{ parseDesc(descItem.value) }}</span>
                    </div>
                    <p v-if="item.remark" class="operation-log-info-panel__remark">{{ item.remark }}</p>
                </a-timeline-item>
            </a-timeline>
            <a-empty
                v-else
                description="暂无操作日志" />
        </div>
        <look-log-list-modal
            :visible="visible"
            :info="info"
            @cancel="visible=false"
        />
    </container-panel>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import { Timeline as ATimeline, Button as AButton, Empty as AEmpty } from 'ant-design-vue'

import { isType } from '@utils/index'

import getOrderLogListService from '@service/order/getOrderLogListService'

export default {
    name: 'OperationLogInfoPagePanel',
    components: {
        ContainerPanel,
        ATimeline,
        ATimelineItem: ATimeline.Item,
        AButton,
        AEmpty,
        LookLogListModal: () => import('./LookLogListModal')
    },
    data() {
        return {
            dataList: [],
            visible: false,
            info: {}
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        this.getList()
    },
    methods: {
    /**
     * 标头
     * */
        parseTitle(item) {
            const { assigneeName, moduleValue, nodeValue, operationalValue } = item
            return `${assigneeName} ${operationalValue} ${moduleValue}-${nodeValue}`
        },
        showListButton(item) {
            return isType('array')(item.old)
        },
        /**
     * 一行内容
     * */
        parseDesc(desc) {
            if (isType('object')(desc)) {
                return `${desc.old} ---> ${desc.new}`
            }
            return ''
        },
        handleLookLogList(item) {
            this.visible = true
            this.info = item
        },
        async getList() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                const param = {
                    riskControlNumber
                }
                this.dataList = await getOrderLogListService(param)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.operation-log-info-panel {
  &__title {
    font-size: 15px;
    font-weight: 600;
  }

  &__remark {
    color: #989999
  }

  &__timeline {
    margin-left: 180px;

    .ant-timeline-item-content {
      font-family: PingFangSC-Light, PingFang SC;

      .timeline-time {
        width: calc(100% - 12px);
        text-align: right;
        left: -100%;
        position: absolute;
        padding-right: 19px;
        color: #989999;
        font-family: PingFangSC-Light, PingFang SC;
      }

      .timeline-log-item {
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #989999;
      }
    }
  }
}
</style>
