<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item
            :label="`是否作为${label}`"
            :prop="formKey"
            :rules="[{
                required: true,
                message: '请选择',
                trigger: 'blur',
            }]">
            <a-radio-group v-model="form[formKey]" name="radioGroup" :disabled="isLook">
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
            </a-radio-group>
            <div v-show="form[formKey]"
                 class="add-edit-grade-factor-page__set-formula-text"
                 @click="visible=true">
                设置{{ label }}
            </div>
        </a-form-model-item>

        <slot />

        <a-form-model-item
            v-if="form[formKey]"
            :disabled="isLook"
            :wrapper-col="{span: 10,offset: 3}">
            <a-input v-model="comment" type="textarea" :disabled="true" />
        </a-form-model-item>
        <set-computed-formula-modal
            :is-look="isLook"
            :factor-data-item="factorDataItem"
            :visible="visible"
            :selected-formula-obj="form"
            @cancel="visible=false"
            @success="handleSuccess" />
    </a-form-model>
</template>

<script>
import { Radio as ARadio, FormModel as AFormModel, Input as AInput } from 'ant-design-vue'

export default {
    name: 'FormulaFormItem',
    components: {
        AInput,
        ARadio,
        ARadioGroup: ARadio.Group,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        SetComputedFormulaModal: () => import('../')
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        formKey: {
            type: String,
            default: 'isFormula'
        },
        label: {
            type: String,
            default: '计算公式'
        },
        isLook: {
            type: Boolean,
            default: false
        },
        factorDataItem: {
            type: Object,
            default: () => ({})
        },
        selectedFormulaObj: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            form: {},
            visible: false,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
    },
    computed: {
        // 公式的name
        comment() {
            return this.form?.formulaDisplay || ''
        }
    },
    watch: {
        value: {
            handler(v) {
                this.form = v || {}
            },
            immediate: true
        }
    },
    methods: {
        handleModalCancel() {
            this.$emit('close')
        },
        /** 设置公式成功回调
         * ｛
         *   formulaDescription: "${13e41abd-c696-423d-9c70-9eed8ab50eb0.zgzzdj}+${13e41abd-c696-423d-9c70-9eed8ab50eb0.zgzzdj}"
         formulaDisplay: "最高资质等级+最高资质等级"
         * ｝
         * */
        handleSuccess(res) {
            this.form = {
                ...this.form,
                ...res
            }
            this.$emit('input', this.form)
            this.visible = false
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                console.log('-> valid', valid)
                if (valid) {
                    res = this.form
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
