<!-- 风控信息 -->
<template>
    <page-layout
        :show-header="false"
        :show-loading="true"
        :loading="loading"
        class="risk-control-info-panel">
        <container-panel>
            <radio-group-panel
                :radio-data="componentsList"
                :default-active-key="activeKey"
                @change="onTabChange"
            />
        </container-panel>
        <container-panel
            v-if="dataInfo"
            class="project-info-panel"
            :no-card-body-padding-top="true">
            <div slot="title">{{ dataInfo.name }}</div>
            <a-descriptions
                bordered
                :column="2"
                class="project-info-panel">
                <a-descriptions-item
                    v-for="item in filterLayoutFieldList"
                    :key="item.id"
                    :label="item.dataVerificationFactorName">
                    {{ showValue(item) }}
                </a-descriptions-item>
            </a-descriptions>

            <div style="margin-top: 20px">
                <!-- 财报 -->
                <template v-if="showFinancial">
                    <financial-list-panel
                        :is-look="true" />
                    <financial-infos />
                </template>

                <!-- 征信报告 -->
                <credit-report
                    v-if="delay(2)"
                    v-show="showSelectReport"
                    ref="reportRef"
                    :is-look="true" />
            </div>
        </container-panel>
    </page-layout>
</template>
<script>
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import getOrderInfoRiskControlInfoService from '@service/order/getOrderInfoRiskControlInfoService'
import { Descriptions as ADescriptions } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import { showFinAndReportFnObj } from '@constant/function'
import { isBool, isDict, isAddress, isDictCascader } from '@views/Task/RiskControlInformation/dataType'
import enumOperate from '@mixins/enumOperate'
import getArea from '@mixins/getArea'
import { getCascaderName } from '@utils/toTree'
import delay from '@mixins/delay'

export default {
    name: 'RiskControlInfoPanel',
    components: {
        PageLayout,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        RadioGroupPanel,
        FinancialListPanel: () => import('@weights/FinancialListPanel'),
        CreditReport: () => import('@weights/CreditReport'),
        FinancialInfos: () => import('@weights/FinancialListPanel/FinancialInfos')
    },
    mixins: [enumOperate, getArea, delay(2)],
    data() {
        return {
            activeKey: '0',
            componentsList: [],
            dataList: [],
            loading: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        dataInfo() {
            const { activeKey, dataList } = this
            return dataList[activeKey]
        },
        /**
         * 财报
         * */
        showFinancial() {
            const { layoutFieldList } = this
            return layoutFieldList.find(item => showFinAndReportFnObj.showFinancial(item))
        },
        /**
         * 是否提供征信报告
         * */
        showSelectReport() {
            const { layoutFieldList } = this
            const find = layoutFieldList.find(item => showFinAndReportFnObj.isSelectReport(item))
            return find && !!+find.riskOrderValue
        },
        layoutFieldList() {
            const { dataList, activeKey } = this
            return dataList[activeKey].layoutFieldList
        },
        // 过滤后的布局数据
        filterLayoutFieldList() {
            const { dataInfo } = this
            const arr = []
            dataInfo.layoutFieldList.forEach(item => {
                const { riskOrderValue, childs } = item
                if (riskOrderValue === null) return
                arr.push(item)
                if (childs) {
                    childs.forEach(childItem => {
                        arr.push(childItem)
                    })
                }
            })
            return arr
        }
    },
    created() {
        this.getData()
    },
    methods: {
        showValue(item) {
            const { riskOrderValue, dataType, dtoList } = item
            if (isBool(dataType)) {
                return +riskOrderValue ? '是' : '否'
            } else if (isDict(dataType)) {
                return this.getApiEnumLabelFromList(dtoList, riskOrderValue, 'name')
            } else if (isAddress(dataType)) {
                return this.getFullArea(riskOrderValue)
            } else if (isDictCascader(dataType)) {
                return getCascaderName(dtoList, riskOrderValue, 'name', 'value')
            }
            return riskOrderValue
        },
        /**
         * tab改变
         */
        onTabChange(value) {
            this.activeKey = value.target.value
        },
        /**
         * 获取风控数据
         * */
        async getData() {
            try {
                this.loading = true
                await this.getAreaList()
                const { riskControlNumber } = this
                this.dataList = await getOrderInfoRiskControlInfoService({
                    riskControlNumber
                })
                this.componentsList = this.dataList.map((item, index) => ({
                    key: index.toString(),
                    label: item.name
                }))
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.risk-control-info-panel {
    .ant-descriptions-item-label .project-info-panel {
        width: $descriptions-item-label-width;
    }

    .page-layout__content {
        padding: 0 16px 16px 16px;
    }
}
</style>
