<!-- 关联单 -->
<template>
    <container-panel class="relevance-info-page-panel" :no-card-body-padding-top="true">
        <div slot="title">关联单列表</div>

        <a-table
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            :loading="loading"
            row-key="id"
            :scroll="{
                x: 1100
            }"
            @change="handleTableChange">
            <!-- 操作 -->
            <template slot="action" slot-scope="text,action">
                <a-button type="link"
                          @click="handleLook(action)">
                    查看
                </a-button>
            </template>
        </a-table>
    </container-panel>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import getOrderRelationListService from '@service/order/getOrderRelationListService'
import { Table as ATable, Button as AButton } from 'ant-design-vue'
import { DEFAULT_PAGES_SIZE } from '@config'
import tableShowTotal from '@mixins/tableShowTotal'


const columns = [
    {
        title: '风控单号',
        align: 'left',
        dataIndex: 'riskControlNumber',
        key: 'riskControlNumber'
    },
    {
        title: '关联类型',
        align: 'left',
        dataIndex: 'associatedType',
        key: 'associatedType'
    },
    {
        title: '提交时间',
        align: 'left',
        dataIndex: 'createDateTime',
        key: 'createDateTime'
    },
    {
        title: '产品分类',
        align: 'left',
        dataIndex: 'riskCategoryName',
        key: 'riskCategoryName'
    },
    {
        title: '项目名称',
        align: 'left',
        dataIndex: 'projectName',
        key: 'projectName'
    },
    {
        title: '投保人',
        align: 'left',
        dataIndex: 'policyHolder',
        key: 'policyHolder'
    },
    {
        title: '保险机构',
        align: 'left',
        dataIndex: 'insuranceAgencyName',
        key: 'insuranceAgencyName'
    },
    {
        title: '订单来源',
        align: 'left',
        dataIndex: 'orderSourceDesc',
        key: 'orderSourceDesc'
    },
    {
        title: '操作',
        dataIndex: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        key: 'action'
    }
]

export default {
    name: 'RelevanceInfoPagePanel',
    components: {
        AButton,
        ContainerPanel,
        ATable
    // RelevanceInfoTabPanel
    },
    mixins: [tableShowTotal],
    data() {
        return {
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    watch: {
        $route: {
            handler() {
                this.getList()
            },
            immediate: true
        }
    },
    methods: {
        handleLook(action) {
            this.$emit('risk', action.riskControlNumber)
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, riskControlNumber } = this
                const param = {
                    current,
                    size: limit,
                    riskControlNumber
                }
                const result = await getOrderRelationListService(param)
                if (!result) {
                    this.dataList = []
                    return
                }
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
</style>
