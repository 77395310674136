<template>
    <a-select
        v-model="principal"
        show-search
        placeholder="请选择"
        @blur="handleBlur"
        @change="handleChange">
        <a-select-option
            v-for="item in dataList"
            :key="item.id"
            :value="item.id"
            :label="item.realName">
            {{ item.realName }}
        </a-select-option>
    </a-select>
</template>

<script>
import {
    Select as ASelect
} from 'ant-design-vue'
import getAssignUserListService from '@service/task/getAssignUserListService'
export default {
    name: 'PrincipalSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        riskControlNumber: {
            type: String,
            default: ''
        },
        // 原代理人
        assigneeName: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        principal: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
    // 选择的name
        handleChange(val) {
            const { dataList } = this
            // const
            const name = dataList.find(item => item.id === val).realName
            this.$emit('change', name)
        },
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            const { riskControlNumber, assigneeName } = this
            try {
                const res = await getAssignUserListService({
                    riskControlNumber
                })
                this.dataList = res.filter(item => item.realName !== assigneeName)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
