// 字段键列表
const FIELD_KEY_LIST = ['masterDataClassificationId', 'factorCode']

/**
 * 获取字段id
 * @param item
 * @returns {string}
 */
export function getId(item = {}) {
    const keyList = FIELD_KEY_LIST.reduce((prev, key) => {
        if (item[key]) {
            prev.push(item[key])
        }
        return prev
    }, [])

    return keyList.join('.')
}

/**
 * 格式化公式字段对象属性
 * @param item
 * @returns {{factorCode: *, factorName: *, id: string, masterDataClassificationId: *}}
 */
export function formatFieldItem(item = {}) {
    const id = getId(item)
    const { masterDataClassificationId, factorCode, factorName } = item
    return {
        id,
        masterDataClassificationId,
        factorCode,
        label: factorName,
        value: id
    }
}

/**
 * 格式化公式字段对象列表
 * @param list
 * @returns {*}
 */
export function formatFieldList(list) {
    return list.map(item => formatFieldItem(item))
}


function substitute(str, o, regexp, mathFunc) {

    // 如果 regexp 不存在 但 mathFunc 存在时
    if (Object.prototype.toString.call(regexp) === '[object Function]') {
        mathFunc = regexp
        regexp = null
    }

    return str.replace(regexp || /\\?\${([^{}]+)}/g, function (match, name) {
        if (mathFunc) {
            return mathFunc(o, match, name)
        } else {
            return o[name] === undefined || o[name] === null ? '' : o[name]
        }
    })
}

/**
 * 解析器
 * @param formula
 * @param fields
 * @returns {*}
 */
export function parser(formula, fields) {


    return substitute(formula, fields, (o, match, name) => {
        if (o[name]) {
            const { label } = o[name]
            return label
        }
        return '空'
    })
}

// 校验异常信息
const VALIDATE_ERROR = {
    FORMULA_IS_EMPTY: '公式为空字符串',
    OPERATOR_CONTINUOUS: '运算符连续',
    BRACKETS_EMPTY: '空括号',
    BRACKETS_UNMATCHED: '括号未匹配',
    OPERATOR_AFTER_LEFT_BRACKET: '(后有运算符',
    OPERATOR_BEFORE_RIGHT_BRACKET: ')前有运算符',
    IS_NOT_AN_OPERATOR_BEFORE_LEFT_BRACKET: '（前不为运算符',
    IS_NOT_AN_OPERATOR_AFTER_RIGHT_BRACKET: ')后不为运算符',
    // eslint-disable-next-line no-template-curly-in-string
    INVALID_VARIABLE: '非法变量：${a}',
    INVALID_SYMBOL: '非法字符',
    NO_NUMBER_OR_ALGEBRAIC_EXPRESSION_AFTER_OPERATOR: '运算符后无数或代数式',
    FIELD_CONTINUOUS: '字段连续'
}

/**
 * 校验
 * @param formula
 * @param fields
 * @returns {string|boolean|*}
 */
export function validate(formula, fields) {


    // 剔除空白符
    formula = formula.replace(/\s/g, '')

    // 公式为空
    if (formula === '') {
        return VALIDATE_ERROR.FORMULA_IS_EMPTY
    }

    // 非法变量
    let illegalVariable = []
    const commentFormula = substitute(formula, fields, (o, match, name) => {
        if (o[name] !== undefined) {
            return '1'
        } else {
            illegalVariable.push(name)
            return '空'
        }
    })

    // 非法变量
    if (illegalVariable.length) {
        return substitute(VALIDATE_ERROR.INVALID_VARIABLE, {
            a: illegalVariable.join('、')
        })
    }

    // 非法字符
    if (/[^()+\-*/0-9]/.test(commentFormula)) {
        return VALIDATE_ERROR.INVALID_SYMBOL
    }

    // 运算符后无数或代数式
    if (/[+\-*/]$/.test(commentFormula)) {
        return VALIDATE_ERROR.NO_NUMBER_OR_ALGEBRAIC_EXPRESSION_AFTER_OPERATOR
    }
    // 连续的字段
    if (/1{2,}/.test(commentFormula)) {
        return VALIDATE_ERROR.FIELD_CONTINUOUS
    }

    // 运算符连续
    if (/[+\-*/]{2,}/.test(commentFormula)) {
        return VALIDATE_ERROR.OPERATOR_CONTINUOUS
    }

    // 空括号
    if (/\(\)/.test(commentFormula)) {
        return VALIDATE_ERROR.BRACKETS_EMPTY
    }


    // 判断括号是否匹配
    let stack = []
    for (let i = 0, item; i < formula.length; i++) {
        item = formula.charAt(i)
        if (item === '(') {
            stack.push('(')
        } else if (item === ')') {
            if (stack.length > 0) {
                stack.pop()
            } else {
                return false
            }
        }
    }
    if (stack.length !== 0) {
        return VALIDATE_ERROR.BRACKETS_UNMATCHED
    }


    // 错误情况，(后面是运算符
    if (/\([+\-*/]/.test(commentFormula)) {
        return VALIDATE_ERROR.OPERATOR_AFTER_LEFT_BRACKET
    }

    // 错误情况，)前面是运算符
    if (/[+\-*/]\)/.test(commentFormula)) {
        return VALIDATE_ERROR.OPERATOR_BEFORE_RIGHT_BRACKET
    }

    // 错误情况，(前面不是运算符
    if (/[^+\-*/]\(/.test(commentFormula)) {
        return VALIDATE_ERROR.IS_NOT_AN_OPERATOR_BEFORE_LEFT_BRACKET
    }

    // 错误情况，)后面不是运算符
    if (/\)[^+\-*/]/.test(commentFormula)) {
        return VALIDATE_ERROR.IS_NOT_AN_OPERATOR_AFTER_RIGHT_BRACKET
    }

    return true
}

/**
 * 向输入域插入字符
 * @param el
 * @param str
 * @returns {string}
 */
export function insertText(el, str) {
    if (document.selection) {
        const sel = document.selection.createRange()
        sel.text = str
    } else if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
        const startPos = el.selectionStart
        const endPos = el.selectionEnd
        let cursorPos = startPos
        const text = el.value
        const prevText = text.substring(0, startPos)
        const nextText = text.substring(endPos, text.length)
        el.value = `${prevText}${str}${nextText}`
        cursorPos += str.length
        el.selectionStart = cursorPos
        el.selectionEnd = cursorPos
    } else {
        el.value += str
    }
    return el.value
}
