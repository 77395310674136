<template>
    <a-select
        v-model="layoutCategory"
        :disabled="disabled"
        placeholder="请选择布局分类"
        @change="handleChange">
        <template v-for="item in dataList">
            <template v-if="item">
                <a-select-option
                    :key="item.id"
                    :value="item.id">
                    {{ item.name }}
                </a-select-option>
            </template>
        </template>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import getLayoutCategoryListService from '@service/ruleEngine/DataCheckSetting/getLayoutCategoryListService'
import { DEFAULT_PAGES_SIZE } from '@config'
import { DATA_CHECK_MODEL_LAYOUT_TYPE } from '@constant/enum'
import { PAGE_SIZE_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'LayoutCategory',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        layoutType: {
            type: Number,
            default: DATA_CHECK_MODEL_LAYOUT_TYPE
        },
        objectId: {
            type: String,
            default: ''
        },
        // 是否开启最大pageSize
        maxPageSize: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // layoutCategory: '',
            dataList: [],
            current: 1
        }
    },
    computed: {
    // 主体ID
        queryId() {
            return this.$route.query.queryId
        },
        // 当前布局分类id 修改使用
        id() {
            return this.$route.query.id
        },
        layoutCategory: {
            get() {
                return this.value || undefined
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleChange() {
            this.$emit('blur')
        },
        async getList() {
            try {
                let pageSize = null
                const { current, queryId, layoutType, maxPageSize } = this
                pageSize = maxPageSize ? PAGE_SIZE_MAX_LENGTH : DEFAULT_PAGES_SIZE
                const param = {
                    current,
                    size: pageSize,
                    objectId: queryId,
                    layoutType
                }
                const result = await getLayoutCategoryListService(param)
                this.dataList = result.records
                // this.current = result.current
                // this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }

}
</script>

<style lang='scss'>

</style>
