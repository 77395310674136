<!-- 详细信息 -->
<template>
    <div
        class="order-detail-info-panel">
        <!-- 订单进度 -->
        <order-steps
            v-if="showOrderStep"
            :risk-order-detail-d-t-o="taskInfo.riskOrderDetailDTO"
            :current-node="taskInfo.riskOrderScheduleDTO.currentNode"
            :have-childs="taskInfo.riskOrderScheduleDTO.haveChilds"
            :process-node-list="taskInfo.riskOrderScheduleDTO.processNodeList" />
        <!--    <audit-state -->
        <!--        :info="taskInfo"/> -->
        <container-panel
            v-if="taskInfo.riskAssessmentDTO && showRiskAssess"
            :no-card-body-padding-top="true">
            <div slot="title">风险评估信息</div>

            <!-- 保司通用自核规则 -->
            <auto-under
                v-if="showAutoUnder"
                :list="taskInfo.roBasicRuleExecutionResults" />

            <!-- 保司自有自核规则 -->
            <self-has-check
                v-if="taskInfo.scInsuranceCompanyResult"
            />
            <!-- 保司自有快速核保规则 -->
            <insure-rule
                v-if="taskInfo.roRuleExecutionResults"
            />

            <!-- 风险评估意见概览 -->
            <risk-assessment
                :execution-count-list="taskInfo.riskAssessmentDTO.roModelExecutionRecords" />

            <!-- 黑名单信息 -->
            <black-list
                v-if="taskInfo.riskAssessmentDTO.showBlackList" />

            <!-- 风险提示 -->
            <risk-warning
                v-if="taskInfo.riskAssessmentDTO.showWrongList" />

            <!-- 特征工程评分等级 -->
            <risk-rating
                v-if="taskInfo.riskAssessmentDTO.showScoreList"
                :rating-type="2" />
            <!-- 风险评分评级 -->
            <risk-rating
                v-if="taskInfo.riskAssessmentDTO.showScoreList" />
        </container-panel>

        <container-panel :no-card-body-padding-top="true">
            <h1 slot="title" style="font-weight: bold; font-size: 20px">基本信息</h1>
            <!-- 基本信息 -->
            <basic-info
                :info="taskInfo.riskOrderDetailDTO" />
            <!-- 关联订单 -->
            <relevance-order
                :associated-order-list="taskInfo.associatedOrderList" />
            <!-- 投保资料 -->
            <insure-material
                :risk-order-file-d-t-o-s="taskInfo.riskOrderFileDTOS" />
        </container-panel>
    </div>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import BasicInfo from '@weights/TaskAndOrderCom/BasicInfo'
import RelevanceOrder from '@weights/TaskAndOrderCom/RelevanceOrder'
import InsureMaterial from '@weights/TaskAndOrderCom/InsureMaterial'
import { orderStateBoolFnObj, isNotRiskControlState } from '@constant/function'

export default {
    name: 'OrderDetailInfoPanel',
    components: {
        // AuditState:()=>import('@weights/AuditState'),
        BasicInfo,
        RelevanceOrder,
        InsureMaterial,
        ContainerPanel,
        OrderSteps: () => import('@weights/TaskAndOrderCom/OrderSteps'),
        BlackList: () => import('@weights/TaskAndOrderCom/Blacklist'),
        RiskAssessment: () => import('@weights/TaskAndOrderCom/RiskAssessment'),
        RiskWarning: () => import('@weights/TaskAndOrderCom/RiskWarning'),
        RiskRating: () => import('@weights/TaskAndOrderCom/RiskRating'),
        AutoUnder: () => import('@weights/TaskAndOrderCom/AutoUnder'),
        SelfHasCheck: () => import('@weights/TaskAndOrderCom/SelfHasCheck'),
        InsureRule: () => import('@weights/TaskAndOrderCom/InsureRule')
    },
    props: {
        taskInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            orderStateBoolFnObj
        }
    },
    computed: {
        // 待录入不显示风险评估信息

        showRiskAssess() {
            const { riskOrderDetailDTO } = this.taskInfo
            if (
                orderStateBoolFnObj.isAwaitEnter(riskOrderDetailDTO)
                && this.$route.name === 'TaskInfo'
                || isNotRiskControlState(riskOrderDetailDTO)) {
                return false
            } else {
                return true
            }
        },
        showAutoUnder() {
            const list = this.taskInfo.roBasicRuleExecutionResults
            return list && list.length
        },
        showOrderStep() {
            const { riskOrderScheduleDTO, riskOrderDetailDTO } = this.taskInfo
            return riskOrderScheduleDTO && !isNotRiskControlState(riskOrderDetailDTO)
        }
    },
    watch: {},
    methods: {}
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.order-detail-info-panel {
    .ant-card-head-title {
        border-bottom: 1px dashed $border-color;
    }
}
</style>
