<!-- 承保（模态框） -->
<template>
    <custom-modal
        title="承保信息"
        class="underwriting-modal"
        :width="648"
        :visible="visible"
        @close="handleClose"
    >
        <template slot="customFooter">
            <!-- 取消 -->
            <a-button @click="handleCancel">取消</a-button>
            <!-- 保存 -->
            <a-button
                type="primary"
                :loading="loading"
                @click="handleSave"
            >
                保存
            </a-button>
        </template>

        <div id="scrollArea" class="scroll-area">
            <!-- 基本信息表单 -->
            <base-info-form-panel
                ref="baseInfoForm"
                @change="handleBaseInfoFormChange"
            />

            <!-- 保单表单-列表 -->
            <template v-if="underwritingResultSelectYes">
                <template v-for="(item, index) in list">
                    <insurance-policy-form-panel
                        ref="insurancePolicyForm"
                        :key="item.key"
                        :index="index"
                        :show-remove-button="index > 0"
                        @on-remove="handleRemove"
                    />
                </template>
            </template>
        </div>

        <div v-if="showAppendButton" class="append-button-wrapper">
            <a-button
                type="link"
                icon="plus"
                @click="handleAppend"
            >
                添加保单
            </a-button>
        </div>
    </custom-modal>
</template>
<script>
// 组件
import { Button } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import BaseInfoFormPanel from './BaseInfoFormPanel'
import InsurancePolicyFormPanel from './InsurancePolicyFormPanel'

import moment from 'moment'
import uuid from 'uuid'
import { validateDateRangeContinuity } from '@utils/validateDateRangeContinuity'

import { RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE } from '@constant/enum'

// 请求
import submitUnderwritingResultService from '@service/riskControlProcurement/submitUnderwritingResultService'

export default {
    name: 'UnderwritingModal',
    components: {
        CustomModal,
        BaseInfoFormPanel,
        InsurancePolicyFormPanel,
        AButton: Button
    },
    props: {
        visible: Boolean,
        riskControlNumber: String
    },
    data() {
        return {
            loading: false,

            // 基础信息
            baseInfo: {
                underwritingResult: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE
            },

            // 保单列表
            list: [
                {
                    key: uuid()
                }
            ]
        }
    },
    computed: {
        underwritingResultSelectYes() {
            const { baseInfo } = this
            return (
                RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE ===
                baseInfo.underwritingResult
            )
        },
        showAppendButton() {
            let { underwritingResultSelectYes, list } = this
            return underwritingResultSelectYes && list.length < 5
        }
    },
    methods: {
        // 提交表单
        async handleSubmit(formData) {
            try {
                this.loading = true
                await submitUnderwritingResultService(formData)

                this.emitQuery()
                this.emitClose()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        // emit query event
        emitQuery() {
            this.$emit('on-query')
        },
        // emit close event
        emitClose() {
            this.list = [
                {
                    key: uuid()
                }
            ]
            this.$emit('on-close')
        },
        // 处理 CustomModal close 事件
        handleClose() {
            this.emitClose()
        },
        // 处理 取消按钮 点击事件
        handleCancel() {
            this.emitClose()
        },
        // 处理 保存按钮 点击事件
        handleSave() {
            const {
                baseInfo,
                underwritingResultSelectYes,
                riskControlNumber = '',
                $refs: {
                    baseInfoForm: baseInfoFormRef,
                    insurancePolicyForm: insurancePolicyFormRefs
                }
            } = this

            let promises = [baseInfoFormRef.validate()]

            insurancePolicyFormRefs.forEach(componentRef => {
                promises.push(componentRef.validate())
            })

            Promise.all(promises).then(() => {
                if (underwritingResultSelectYes) {
                    let formList = this.frmtList()

                    validateDateRangeContinuity(formList).then(
                        () => {
                            let _formData = {
                                ...baseInfo,
                                riskControlNumber,
                                formList
                            }
                            this.handleSubmit(_formData)
                        },
                        day => {
                            let _message = ''
                            if (day < 0) {
                                _message = '多保单的保险期限之间存在重合！'
                            } else {
                                _message = '多保单的保险期限之间存在断档！'
                            }
                            this.$message.warning(_message)
                        }
                    )
                } else {
                    let _formData = {
                        ...baseInfo,
                        riskControlNumber
                    }
                    this.handleSubmit(_formData)
                }
            })
        },
        // 添加保单，每次添加完成，滚动条自动滚动至底
        handleAppend() {
            this.list.push({
                key: uuid()
            })

            this.$nextTick(() => {
                let scrollTarget = document.getElementById('scrollArea')
                scrollTarget.scrollTop = scrollTarget.scrollHeight
            })
        },
        // 删除保单
        handleRemove(index) {
            this.list.splice(index, 1)
        },
        // 处理基础信息表单 change 事件
        handleBaseInfoFormChange(formData) {
            this.baseInfo = formData
        },
        // 格式化 list，将日期由 moment 对象 转为 日期字符串，移除字段 key
        frmtList() {
            return this.$refs.insurancePolicyForm.map(formRef => {
                const {
                    dateRange: [startTime, endTime],
                    policyDocument,
                    ...retItem
                } = formRef.form

                let startTimeStr = moment(startTime).format('YYYY-MM-DD')
                startTimeStr += ' 00:00:00'

                let endTimeStr = moment(endTime).format('YYYY-MM-DD')
                endTimeStr += ' 23:59:59'

                return {
                    ...retItem,
                    insuranceFilePath: JSON.stringify(policyDocument),
                    startTime: startTimeStr,
                    endTime: endTimeStr
                }
            })
        }
    }
}
</script>
<style lang="scss">
.underwriting-modal {
    .ant-modal-body {
        padding-bottom: 0;
        .scroll-area {
            max-height: 707px;
            overflow-y: auto;
        }
        .append-button-wrapper {
            text-align: center;
            padding: 10px 0;
        }
    }
}
</style>
