<!-- 投保资料 -->
<template>
    <table-panel class="insure-material-page-panel">
        <div slot="title">投保资料</div>
        <file-material
            :data-list="riskOrderFileDTOS" />
    </table-panel>
</template>

<script>
import TablePanel from '@components/TablePanel'
export default {
    name: 'InsureMaterialPagePanel',
    components: {
        TablePanel,
        FileMaterial: () => import('@weights/FileMaterial')
    },
    props: {
        riskOrderFileDTOS: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
            dataList: [],
            currentImg: '',
            visible: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
    },
    methods: {}
}
</script>

<style lang="scss">
.insure-material-page-panel {
  width: 100%;

  &__img-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin-right: 50px;
    }
  }
}
</style>
