<!--  设置计算公式弹窗 -->
<template>
    <custom-modal
        title="设置计算公式"
        :visible="visible"
        :mask-closable="false"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button v-if="!isLook"
                      key="submit"
                      type="primary"
                      :loading="loading"
                      @click="handleOk">
                提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :is-look="isLook"
            :selected-formula-obj="selectedFormulaObj"
            :factor-data-item="factorDataItem"
            @loading="handleLoading"
            @success="handleSuccess"
            @comment="handleComment" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'

export default {
    name: 'SetComputedFormulaModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
    // 公式对象回显
        selectedFormulaObj: {
            type: Object,
            default() {
                return {}
            }
        },
        // 因子信息
        factorDataItem: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {
        handleLoading(value) {
            this.loading = value
        },
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.$refs.formPanel.handleSubmit()
        },
        handleSuccess(res) {
            this.loading = false
            this.$emit('success', res)
        },
        handleComment(val) {
            this.$emit('comment', val)
        }
    }
}
</script>

<style lang='scss'>

</style>
