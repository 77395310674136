<!-- 基本信息 -->
<template>
    <div class="basic-info">
        <template v-if="isRiskProcurement">
            <template v-for="item in columns">
                <info-panel
                    v-if="showInfoPanel(item)"
                    :key="item.key"
                    :title="item.title"
                    :columns="item.columns"
                    :like-table="item.likeTable"
                    :is-insurance-policy="item.isInsurancePolicy"
                    :record="item.record || {}"
                    :get-popup-container="getPopupContainer"
                />
            </template>
        </template>
        <template v-else>
            <a-descriptions bordered :column="2" class="basic-information-panel">
                <a-descriptions-item label="项目名称">
                    {{ empty(info.projectName) }}
                </a-descriptions-item>
                <a-descriptions-item label="产品分类">
                    {{ empty(info.riskCategoryName) }}
                </a-descriptions-item>
                <a-descriptions-item label="投保人">
                    {{ empty(info.policyHolder) }}
                </a-descriptions-item>
                <a-descriptions-item label="保险模式">
                    {{ empty(info.insuranceModelName) }}
                </a-descriptions-item>
                <a-descriptions-item label="其他投保人">
                    {{ empty(info.otherPolicyHolder) }}
                </a-descriptions-item>
                <a-descriptions-item label="保险金额（元）">
                    {{ empty(info.insuranceAmount) }}
                </a-descriptions-item>
                <a-descriptions-item label="被保险人">
                    {{ empty(info.theInsured) }}
                </a-descriptions-item>
                <a-descriptions-item label="费率">
                    <template v-if="info.rate">
                        {{ accMul(+info.rate).toFixed(2) + '%' }}
                    </template>
                    <template v-else>/</template>
                </a-descriptions-item>
                <a-descriptions-item label="保险期限">
                    <template v-if="info.startTime">
                        {{ info.startTime }}至{{ info.endTime }}
                    </template>
                    <template v-else>/</template>
                </a-descriptions-item>
                <a-descriptions-item label="保费(元）">
                    {{ empty(info.premium) }}
                </a-descriptions-item>
                <a-descriptions-item label="项目所在地">
                    {{ empty(info.areaName) }}
                </a-descriptions-item>
                <a-descriptions-item
                    v-for="item in info.valueNames"
                    :key="item.productSpecName"
                    :label="item.productSpecName">
                    {{ item.attributeName }}
                </a-descriptions-item>
                <a-descriptions-item label="反担保措施">
                    {{ empty(info.counterGuaranteeMeasuresValue) }}
                </a-descriptions-item>
                <a-descriptions-item label="订单来源">
                    {{ empty(info.orderSourceDesc) }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </div>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'
import empty from '@mixins/empty'
import { accMul } from '@utils/calculate'
import { ORDER_SOURCE_RISK_PROCUREMENT_TYPE } from '@constant/enum'

import '@views/RiskControlProcurement/OrderInfo/style.scss'
import {
    classifiedByStockCategoryColumns,
    baseInfoColumns
} from '@views/RiskControlProcurement/OrderInfo/mixins'

export default {
    name: 'BasicInformationPanel',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        InfoPanel: () => import('@views/RiskControlProcurement/OrderInfo/InfoPanel')
    },
    mixins: [empty],
    props: {
        info: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            accMul,
            loading: false
        }
    },
    computed: {
        // 风控采购
        isRiskProcurement() {
            const { orderSource } = this.info
            return orderSource === ORDER_SOURCE_RISK_PROCUREMENT_TYPE
        },
        columns() {
            const { info, isRiskProcurement } = this
            if (!isRiskProcurement) return []
            const { stockCategory } = info
            const {
                insuranceInfoColumns,
                insuredInfoColumns,
                policyholderInfoColumns,
                projectInfoColumns
            } = classifiedByStockCategoryColumns[stockCategory]
            return [
                this.getColumnsInfo(1, '基本信息', baseInfoColumns, info || {}),
                this.getColumnsInfo(2, '项目信息', projectInfoColumns, info || {}),
                this.getColumnsInfo(3, '投保人信息', policyholderInfoColumns, info || {}),
                this.getColumnsInfo(4, '被保险人信息', insuredInfoColumns, info || {}),
                this.getColumnsInfo(5, '保险信息', insuranceInfoColumns, info || {})
            ].filter(item => projectInfoColumns || !projectInfoColumns && item.key !== 2)
        }
    },
    methods: {
        getPopupContainer() {
            return document.querySelector('.basic-info')
        },
        getColumnsInfo(key, title, columns, record = {}) {
            return {
                key,
                title,
                likeTable: true,
                columns,
                record
            }
        },
        showInfoPanel({ columns }) {
            return Array.isArray(columns) && columns.length > 0
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.basic-information-panel {
    .ant-descriptions-item-label {
        width: $descriptions-item-label-width;
    }
}
</style>
