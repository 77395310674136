<template>
    <div class="step-child">
        <div v-for="(item,index) in list" :key="index">
            <div class="step-child_item"
                 :class="{defaultBorderLeft:index===0,activeBorderLeft:index===0&&active,activeBorderTop:active}">
                <div class="step-child_item_content">
                    <div class="yuan" :class="{active}" />
                    <div>{{ item.nodeName }}</div>
                    <div v-if="item.executeDate" style="font-size: 14px">{{ item.executeDate }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepChild',
    components: {},
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        },
        // 是否高亮
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang='scss'>
.step-child {
    display: flex;
    position: absolute;
    height: 60px;
    margin-top: -105px;
    margin-left: 60px;


    &_item {
        position: relative;
        min-width: 140px;
        height: 100%;
        margin-right: 20px;
        border-top: 3px solid rgba(0, 0, 0, 0.25);
        border-top-left-radius: 3px;

        &_content {
            position: absolute;
top: -15px;
left: 80px;
width: 100%;
        }
    }

    .defaultBorderLeft {
        border-left: 3px solid rgba(0, 0, 0, 0.25);
    }

    .activeBorderLeft {
        border-left: 3px solid #1890ff;
    }

    .activeBorderTop {
        border-top: 3px solid #1890ff !important;
    }


    .yuan {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.25);
        margin: 0 5px 0 5px;
    }

    .active {
        background-color: #1890ff !important;
    }
}
</style>
