<template>
    <a-form-model
        ref="ruleForm"
        class="set-computed-formula-modal"
        layout="horizontal"
        :label-col="{ span: 24}"
        :wrapper-col="{ span: 24 }"
        :model="form"
        :rules="rules">
        <a-form-model-item :label="label" prop="formula">
            <div v-show="comment">{{ comment }}</div>
            <a-textarea
                v-model="form.formula"
                ref="textarea"
                :rows="6"
                placeholder="请设置计算公式"
                allow-clear
                :disabled="isLook" />
            <a-space v-if="!isLook">
                <a-popover v-model="popoverVisible"
                           class="set-computed-formula-modal__popover"
                           arrow-point-at-center
                           overlay-class-name="set-computed-formula-modal__popover-content"
                           placement="bottom">
                    <template slot="content">
                        <field-select :data="fieldList" @select="handleFieldSelected" />
                    </template>
                    <a-button class="set-computed-formula-modal__button">插入字段</a-button>
                </a-popover>

                <a-dropdown
                    v-for="dropdown in dropdownList"
                    :key="dropdown.id">
                    <a-button class="set-computed-formula-modal__button">{{ dropdown.name }}</a-button>
                    <a-menu slot="overlay" style="width: 150px">
                        <a-menu-item
                            v-for="item in dropdown.menuItems"
                            :key="item.value"
                            @click="handleSymbolSelected(item)">
                            {{ item.label }}
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-button class="set-computed-formula-modal__button-check"
                          @click="handleValidateButtonClick"
                >
                    语法检查
                </a-button>
            </a-space>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput,
    Button as AButton,
    Space as ASpace,
    Dropdown as ADropdown,
    Menu as AMenu,
    Popover as APopover
} from 'ant-design-vue'

import FieldSelect from './FieldSelect'

import { formatFieldList, insertText, parser, validate } from './core'

import getRiskFactorAllListService from '@service/ruleEngine/riskFactor/getRiskFactorAllListService'


export default {
    name: 'SetComputedFormulaModal',
    components: {
        AFormModel,
        AButton,
        ASpace,
        ADropdown,
        AMenu,
        AMenuItem: AMenu.Item,
        ATextarea: AInput.TextArea,
        AFormModelItem: AFormModel.Item,
        FieldSelect,
        APopover
    },
    props: {
        formulaName: {
            type: String,
            default: ''
        },
        factorDataItem: {
            type: Object,
            default() {
                return {}
            }
        },
        // 已设置的公式对象
        selectedFormulaObj: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdownList: [
                {
                    id: 1,
                    name: '插入运算符号',
                    menuItems: [
                        {
                            value: '+',
                            label: '+ 加'
                        },
                        {
                            value: '-',
                            label: '- 减'
                        },
                        {
                            value: '*',
                            label: 'x 乘'
                        },
                        {
                            value: '/',
                            label: '/ 除'
                        }
                    ]
                }, {
                    id: 2,
                    name: '插入括号',
                    menuItems: [
                        {
                            value: '(',
                            label: '( 左括号'
                        }, {
                            value: ')',
                            label: ') 右括号'
                        }
                    ]
                }
            ],
            form: {
                // 公式
                formula: ''
            },
            // 公式描述
            comment: '',
            textModel: '',
            rules: {
                formula: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请设置计算公式'
                    }
                ]
            },
            // 字段列表
            fieldList: [],
            // 设置字段popover显示控制
            popoverVisible: false
        }
    },
    computed: {
        label() {
            const { factorDataItem } = this
            if (!factorDataItem || !factorDataItem.factorName) {
                return ''
            }
            return `${factorDataItem.factorName}=`
        },
        // 字段集合
        fields() {
            const { fieldList } = this
            return fieldList.reduce((prev, item) => {
                const { id } = item
                prev[id] = item
                return prev
            }, {})
        }
    },
    watch: {
        'form.formula': {
            handler(formula) {
                if (!formula) return
                const { fields } = this
                this.comment = parser(formula, fields)
            }
        },
        fieldList: {
            handler() {
                const { selectedFormulaObj } = this
                if (!selectedFormulaObj) return
                const { formulaDescription } = selectedFormulaObj
                this.form.formula = formulaDescription
            }
        }
    },
    mounted() {
        this.getRiskFactorAllList()
    },
    methods: {
        /**
         * 获取字段列表
         * @returns {Promise<void>}
         */
        async getRiskFactorAllList() {
            try {
                const result = await getRiskFactorAllListService()
                this.fieldList = formatFieldList(result)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        /**
         * 插入符号
         */
        handleSymbolSelected(record) {
            const { value } = record
            this.insert('insertSymbol', value)
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { form, fields, comment } = this
                    const result = validate(form.formula, fields)
                    if (result !== true) {
                        this.$message.error(result)
                        return
                    }
                    let res = {
                        formulaDescription: form.formula, // 公式定义，
                        formulaDisplay: comment // 公式描述
                    }
                    this.$emit('success', res)
                }
            })
        },
        /**
         * 字段选中事件
         */
        handleFieldSelected(field) {
            this.popoverVisible = false
            this.insert('insertField', field)
        },
        /**
         * 指令集
         */
        insert(command, value) {
            const commandList = {
                // 插入字段
                insertField: value => {
                    this.insertText(`\${${value}}`)
                },
                // 插入符号
                insertSymbol: value => {
                    this.insertText(value)
                }
            }

            const commandFunc = commandList[command] || commandList.insertSymbol

            commandFunc(value)
        },
        /**
         * 向公式textarea插入字段
         * @param text
         */
        insertText(text) {
            const el = this.$refs.textarea.$el.firstChild
            this.form.formula = insertText(el, text)
        },
        handleValidateButtonClick() {
            const { form, fields } = this
            const result = validate(form.formula, fields)
            if (result === true) {
                this.$message.success('校验成功')
            } else {
                this.$message.error(result)
            }
        }
    }
}
</script>

<style lang='scss'>
.set-computed-formula-modal {
    &__button {
        background: #eaeaec
    }

    &__button-check {
        background: #ecf6ff;
        color: #1890ff;
        margin-left: 70px;
    }

    &__popover {

        &-content {
            padding-top: 0;

            .ant-popover-arrow {
                display: none;
            }

            .ant-popover-inner-content {
                padding: 0;
            }
        }

        //padding: 0;
    }
}
</style>
