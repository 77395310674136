<template>
    <a-select
        v-model="modelValue"
        mode="multiple"
        style="width: 100%"
        :placeholder="placeholder"
        :filter-option="filterOption"
        class="select-option-label-prop"
        option-label-prop="label"
        @blur="onBlur"
        @search="onSearch"
        @focus="handleFocus">
        <a-select-option
            v-for="item in viewData"
            :key="item[optionValue]"
            :value="item[optionValue]"
            :label="item[optionLabel]">
            {{ item[optionLabel] }}
        </a-select-option>
        <div slot="dropdownRender" slot-scope="menu">
            <v-nodes :vnodes="menu" />
            <div class="select-option-label-prop__pagination"
                 @mousedown="e => e.preventDefault()">
                <slot name="footer" />
            </div>
        </div>
    </a-select>
</template>

<script>
import {
    Select as ASelect
} from 'ant-design-vue'

export default {
    name: 'SelectOptionLabelProp',
    components: {
        ASelect,
        ASelectOption: ASelect.Option,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    props: {
        optionValue: {
            type: String,
            default: 'id'
        },
        optionLabel: {
            type: String,
            default: 'name'
        },
        selectOptionsData: {
            type: Array,
            default() {
                return []
            }
        },
        // 是否使用组件自带的过滤
        filterOption: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            default() {
                return []
            }
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            originData: [], // 原始data
            viewData: []// 视图渲染data
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.onSearch('')
                this.$emit('input', val)
            }
        }
    },
    watch: {
        selectOptionsData: {
            handler(val) {
                this.viewData = val
                this.originData = val
            },
            immediate: true
        }
    },
    methods: {
        handleFocus() {
            this.$emit('focus')
        },
        onBlur() {
            this.$emit('blur')
        },
        onSearch(val) {
            const { originData, optionLabel } = this
            this.viewData = val === '' ? originData : originData.filter(item => item[optionLabel].includes(val))
            this.$emit('search', val)
        }
    }
}
</script>

<style lang='scss'>
.select-option-label-prop {
    &__pagination {
        padding: 15px;
        float: right;
    }
}

</style>
