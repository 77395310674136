<!-- 保司通用核保规则 -->
<template>
    <table-panel class="risk-assessment-page-panel">
        <div slot="title">{{ BAOSI_TONGYONG_ZIHE_GUIZE }}</div>
        <base-rule-table :list="list" />
    </table-panel>
</template>

<script>
import { COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_LABEL as BAOSI_TONGYONG_ZIHE_GUIZE } from '@constant/enum'
export default {
    name: 'AutoUnder',
    components: {
        TablePanel: () => import('@components/TablePanel'),
        BaseRuleTable: () => import('../common/BaseRuleTable')
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            BAOSI_TONGYONG_ZIHE_GUIZE
        }
    }
}
</script>
