<!-- 风险评估 -->
<template>
    <page-layout
        :show-header="false"
        :show-loading="true"
        :loading="loading"
        class="risk-assess-info-panel">
        <container-panel>
            <radio-group-panel
                :radio-data="componentsList"
                :default-active-key="activeKey"
                @change="onTabChange"
            />
        </container-panel>
        <container-panel
            v-if="dataInfo"
            class="project-info-panel"
            :no-card-body-padding-top="true">
            <div slot="title">{{ dataInfo.name }}</div>
            <a-descriptions bordered :column="1" class="project-info-panel">
                <a-descriptions-item
                    v-for="item in dataInfo.assessmentContentValueList"
                    :key="item.id"
                    :label="item.riskAssessmentContentName">
                    {{ item.riskOrderValue }}
                </a-descriptions-item>

                <template v-if="showWarn">
                    <a-descriptions-item
                        label="承保意见">
                        {{ getApiEnumLabelFromList(INSURE_TYPE_ENUM, resObj.underwritingOpinions) }}
                    </a-descriptions-item>
                    <a-descriptions-item
                        label="风险提示">
                        <div v-html="resObj.riskWarningList.join('<br/>')" />
                    </a-descriptions-item>
                </template>
            </a-descriptions>
        </container-panel>
    </page-layout>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import { Descriptions as ADescriptions } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import getRiskAssessService from '@service/task/getRiskAssessService'
import { INSURE_TYPE_ENUM } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'
export default {
    name: 'RiskAccessInfoPanel',
    components: {
        PageLayout,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        RadioGroupPanel
    // ApplicantAnalysisPanel: () =>
    //   import("./FinancialListPanel/ApplicantAnalysisPanel"),
    // ProjectAnalysisPanel: () =>
    //   import("./FinancialListPanel/ProjectAnalysisPanel"),
    // ReviewConclusionPanel: () => import("./FinancialListPanel/ReviewConclusionPanel"),
    },
    mixins: [enumOperate],
    data() {
        return {
            INSURE_TYPE_ENUM,
            resObj: {},
            dataList: [],
            activeKey: '0',
            componentsList: []
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        dataInfo() {
            const { activeKey, dataList } = this
            return dataList[activeKey]
        },
        showWarn() {
            const { dataList, activeKey } = this
            const len = dataList.length
            return len === +activeKey + 1
        }
    },
    created() {
        this.getData()
    },
    methods: {
    /**
     * tab改变
     */
        onTabChange(value) {
            this.activeKey = value.target.value
        },
        /**
     * 获取評估数据
     * */
        async getData() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.resObj = await getRiskAssessService({
                    riskControlNumber
                })
                this.dataList = this.resObj.layoutResultDTO // 布局list
                this.componentsList = this.dataList.map((item, index) => ({
                    key: index.toString(),
                    label: item.name
                }))
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.risk-assess-info-panel {
  .ant-descriptions-item-label {
    width: $descriptions-item-label-width;
  }
  .page-layout__content {
    padding: 0 16px 16px 16px;
  }
}
</style>
