<template>
    <div class="head-com">
        <container-panel class="head-com-panel">
            <div slot="title" style="font-weight: 600; font-size: 15px">单号：{{ riskOrderDetailDTO.riskControlNumber }}</div>
            <div slot="button">
                <slot name="headButton" />
            </div>
            <div class="head-com__info">
                <div class="head-com__info-panel">
                    <a-descriptions>
                        <a-descriptions-item label="下单人">
                            {{ riskOrderDetailDTO.createName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="业务类型">
                            {{ BUSINESS_TYPE_MAP[riskOrderDetailDTO.riskBusinessType] }}
                        </a-descriptions-item>
                        <a-descriptions-item label="产品分类">
                            {{ riskOrderDetailDTO.riskCategoryName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="更新时间">
                            {{ riskOrderDetailDTO.createDateTime }}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
                <div class="head-com__head-right-desc">
                    <span class="head-com__head-right-desc__label">状态:</span>
                    <span class="head-com__head-right-desc__value">{{ state }}</span>
                </div>
            </div>
            <slot />
        </container-panel>
    </div>
</template>

<script>
import {
    Descriptions as ADescriptions
} from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import enumOperate from '@mixins/enumOperate'
import {
    BUSINESS_TYPE_MAP, ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
    ORDER_STATE_TYPE_ENUM_LIST, ORDER_BUSINESS_STATE_ENUM_LIST
} from '@constant/enum'
import { ORDER_MANAGE_ORDER_INFO_DOWN_LOAD_REPORT_BUTTON } from '@constant/authEnum/order'

export default {
    name: 'OrderInfo',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel
    },
    mixins: [enumOperate],
    props: {
        riskOrderDetailDTO: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            ORDER_MANAGE_ORDER_INFO_DOWN_LOAD_REPORT_BUTTON,
            BUSINESS_TYPE_MAP,
            ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
            ORDER_STATE_TYPE_ENUM_LIST
        }
    },
    computed: {
        state() {
            const { riskOrderDetailDTO } = this
            const { businessState, stateValue } = riskOrderDetailDTO
            if (businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE) {
                return stateValue
            } else {
                return this.getApiEnumLabelFromList(ORDER_BUSINESS_STATE_ENUM_LIST, businessState)
            }
        }
    },
    watch: {},
    methods: {}
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.head-com {
  padding-bottom: 15px;
  &__info {
    margin-top: -24px;
    display: flex;
  }

  &__title {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #0f0f0f;
  }

  &__info-panel {
    flex: 1;
  }

  &__info-status {
    width: 250px;
    text-align: right;
    padding-right: 5px;
  }

  .ant-btn {
    margin-right: 0 !important;
  }
  .head-com-panel {
    .ant-card-body {
     padding: $ant-card-body-padding $ant-card-body-padding 0;
    }

    .ant-tabs {
      border-top: 1px dashed $border-color;
    }
  }
  &__head-right-desc {
    text-align: right;

    &__label {
      color: #999;
      padding-right: 5px;
    }

    &__value {
      color: #0f0f0f
    }
  }
}
</style>
