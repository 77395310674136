<template>
    <div class="computing-formula-field-select">
        <a-input v-model="query" placeholder="请输入搜索内容" />
        <a-menu prefix-cls="ant-dropdown-menu"
                class="computing-formula-field-select__menu"
                :selectable="false"
        >
            <template v-if="queryList.length">
                <template v-for="item in queryList">
                    <a-menu-item :key="item.id"
                                 @click="handleItemClick"
                    >
                        {{ item.label }}
                    </a-menu-item>
                </template>
            </template>
            <template v-else>
                <a-empty />
            </template>
        </a-menu>
    </div>
</template>

<script>
import {
    Input as AInput,
    Menu as AMenu,
    Empty as AEmpty
} from 'ant-design-vue'


export default {
    name: 'FieldSelect',
    components: {
        AInput,
        AMenu,
        AMenuItem: AMenu.Item,
        AEmpty
    },
    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            query: ''
        }
    },
    computed: {
        queryList() {
            const { query, data } = this
            if (query) {
                return data.filter(item => {
                    const { label } = item
                    return label.includes(query)
                })
            }
            return data
        }
    },
    mounted() {
    },
    methods: {
        handleItemClick({ key }) {
            this.$emit('select', key)
        }
    }
}
</script>

<style lang="scss">
.computing-formula-field-select {
  width: 288px;

  &__menu {
    height: 200px;
    overflow: auto;
  }
}
</style>
