<template>
    <search-panel
        class="task-order-search-panel"
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh">
        <form-col v-bind="formLayout" label="风控单号" prop="riskControlNumber">
            <a-input
                v-model="form.riskControlNumber"
                slot="formElements"
                placeholder="请输入风控单号" />
        </form-col>
        <form-col v-bind="formLayout" label="项目名称" prop="projectName">
            <a-input
                v-model="form.projectName"
                slot="formElements"
                placeholder="请输入项目名称" />
        </form-col>
        <form-col v-bind="formLayout" label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId"
                                       slot="formElements" />
        </form-col>
        <template slot="expansion">
            <form-col v-bind="formLayout" label="订单状态" prop="state">
                <template slot="formElements">
                    <a-select v-model="form.state" :allow-clear="true" placeholder="请选择订单状态">
                        <a-select-option
                            v-for="item in ORDER_STATE_TYPE_ENUM_LIST"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>
            <form-col v-bind="formLayout" label="审核状态" prop="businessState">
                <template slot="formElements">
                    <a-select v-model="form.businessState" :allow-clear="true" placeholder="请选择审核状态">
                        <a-select-option
                            v-for="item in ORDER_BUSINESS_STATE_ENUM_LIST"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>
            <form-col v-bind="formLayout" label="投保人" prop="policyHolder">
                <template slot="formElements">
                    <a-input v-model="form.policyHolder" placeholder="请输入投保人" />
                </template>
            </form-col>
            <!--      <form-col  v-bind="formLayout" label="保险机构" prop="insuranceAgencyId"> -->
            <!--        <a-select slot="formElements" -->
            <!--                  v-model="form.insuranceAgencyId" -->
            <!--                  placeholder="请选择保险机构"> -->
            <!--          <a-select-option :value="1">机构1</a-select-option> -->
            <!--          <a-select-option :value="2">机构2</a-select-option> -->
            <!--          <a-select-option :value="3">机构3</a-select-option> -->
            <!--        </a-select> -->
            <!--      </form-col> -->
            <form-col v-bind="formLayout" label="项目所在地" prop="area">
                <area-cascader
                    v-model="form.area"
                    slot="formElements"
                    :show-county="false" />
            </form-col>
            <form-col v-bind="formLayout" label="关联订单号" prop="associatedOrderNumber">
                <template slot="formElements">
                    <a-input v-model="form.associatedOrderNumber" placeholder="请输入关联订单号" />
                </template>
            </form-col>
            <form-col v-bind="formLayout" label="订单来源" prop="orderSource">
                <order-source-select v-model="form.orderSource" slot="formElements" />
            </form-col>
            <form-col v-bind="formLayout" label="提交时间" prop="formTime">
                <submit-time-section
                    v-model="form.formTime"
                    slot="formElements"
                    @time="handleTime" />
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import {
    Input as AInput,
    Select as ASelect
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'
import {
    ORDER_STATE_TYPE_ENUM_LIST, ORDER_BUSINESS_STATE_ENUM_LIST
} from '@constant/enum'
import { filterParams } from '@utils/search'

export default {
    name: 'OrderListSearchpanel',
    components: {
        AreaCascader: () => import('@weights/AreaCascader'),
        SubmitTimeSection: () => import('@weights/SubmitTimeSection'),
        AInput,
        ASelect,
        ASelectOption: ASelect.Option,
        ProductCategoryCascader,
        SearchPanel,
        FormCol,
        OrderSourceSelect: () => import('@components/select/OrderSourceSelect')
    },
    data() {
        return {
            ORDER_STATE_TYPE_ENUM_LIST,
            ORDER_BUSINESS_STATE_ENUM_LIST,
            form: {
                businessState: undefined,
                riskControlNumber: '',
                projectName: '',
                area: [],
                riskCategoryId: '', // 分类
                policyHolder: '', // 投保人
                insuranceAgencyId: undefined, // 保险机构
                associatedOrderNumber: '', // 关联订单号
                projectArea: undefined,
                formTime: [],
                createDateTime: '', // 时间
                state: undefined, // 订单状态
                orderSource: undefined, // 订单来源
                proviceCode: '',
                cityCode: '',
                areaCode: '',
                createDateTimeStart: '',
                createDateTimeEnd: ''
            },
            formLayout: {
                span: 6,
                labelSpan: 5,
                wrapperSpan: 18
            }
        }
    },
    computed: {
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formTime, area, ...params } = this.form
            return params
        }
    },
    watch: {
        'form.area': {
            handler(val) {
                [this.form.proviceCode, this.form.cityCode = '', this.form.areaCode = ''] = val
            }
        }
    },
    methods: {
        handleTime(val) {
            if (val.includes('')) {
                this.form.createDateTimeStart = ''
                this.form.createDateTimeEnd = ''
            } else {
                this.form.createDateTimeStart = val[0] + ' 00:00:00'
                this.form.createDateTimeEnd = val[1] + ' 00:00:00'
            }
        },
        handleSearch() {
            const { params } = this
            this.$emit('search', filterParams(params))
        },
        handleRefresh() {
            this.form.createDateTime = ''
            this.form.createDateTimeStart = ''
            this.form.createDateTimeEnd = ''
            const { params } = this
            this.$emit('refresh', filterParams(params))
        },
        handleTimeChange(moment, stringTime) {
            this.form.createDateTime = stringTime
        }
    }
}
</script>

<style lang="scss">
.task-order-search-panel {
    .ant-form-item {
        .ant-form-item-label {
            min-width: 90px;
            text-align: right;
        }
    }
}
</style>
