<template>
    <a-select
        v-model="riskBusinessType"
        placeholder="请选择业务类型"
        @blur="onBlur">
        <template v-for="item in BUSINESS_TYPE_ENUM_LIST">
            <a-select-option
                :key="item.value"
                :value="item.value"
                :disabled="item.disabled">
                {{ item.label }}
            </a-select-option>
        </template>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'

import { BUSINESS_TYPE_ENUM_LIST } from '@constant/enum'

export default {
    name: 'RiskBusinessType',
    components: {
        ASelect,
        ASelectOption: ASelect.Option

    },
    props: {
        value: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            BUSINESS_TYPE_ENUM_LIST
        }
    },
    computed: {
        riskBusinessType: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        onBlur() {
            this.$emit('blur')
        }
    }
}
</script>

<style lang='scss'>

</style>
