<!-- 订单进度 -->
<template>
    <container-panel>
        <div slot="title">订单进度</div>
        <div class="order-detail-info-panel_steps-wrapper">
            <a-steps class="order-detail-info-panel_steps" :class="{pt:haveChilds}" :current="step" progress-dot>
                <a-step v-for="(item,index) in processNodeList" :key="item.nodeType">
                    <span slot="title" style="position: relative; width: 120px; display: inline-block">
                        {{ item.nodeName }}
                        <!-- 分叉节点 -->
                        <step-child
                            v-if="item.childs && item.childs.length"
                            :active="step>=index"
                            :list="item.childs" />
                    </span>
                    <span v-if="item.executeDate" slot="description">
                        {{ item.executeDate }}
                        <span
                            v-if="showLookIdea(item)"
                            style="cursor: pointer"
                            @click="handleLook(item)">查看意见
                        </span>
                    </span>
                </a-step>
            </a-steps>
        </div>
        <look-idea-modal
            :visible="visible"
            :info="lookInfo"
            @cancel="visible=false" />
    </container-panel>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import {
    Steps as ASteps
} from 'ant-design-vue'
import {
    CAN_LOOK_IDEA_MAP
} from '@constant/enum'

export default {
    name: 'OrderStepsPanel',
    components: {
        ContainerPanel,
        ASteps,
        AStep: ASteps.Step,
        LookIdeaModal: () => import('./LookIdeaModal'),
        StepChild: () => import('./StepChild')
    },
    props: {
        taskInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 步骤条数据
        processNodeList: {
            type: Array,
            default() {
                return []
            }
        },
        // 当前步骤
        currentNode: {
            type: Number,
            default: 0
        },
        // 基本信息
        riskOrderDetailDTO: {
            type: Object,
            default() {
                return {}
            }
        },
        haveChilds: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            step: 1,
            lookInfo: {}
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    watch: {
        currentNode: {
            handler() {
                this.initStep()
            },
            immediate: true
        },
        processNodeList: {
            handler(val) {
                console.log(val)
            },
            immediate: true
        }
    },
    methods: {
        initStep() {
            const { processNodeList, currentNode } = this
            // 当前步骤
            this.step = processNodeList.findIndex(item => item.sort === currentNode)
        },
        showLookIdea(item) {
            const { nodeType } = item
            return CAN_LOOK_IDEA_MAP[nodeType] !== undefined
        },
        handleLook(item) {
            this.lookInfo = item
            this.visible = true
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.order-detail-info-panel {
    &_steps-wrapper {
        overflow: auto;
    }

    &_steps {
        overflow: auto;
        width: auto;
        padding-top: 5px;
    }

    .ant-card-head-title {
        border-bottom: 1px dashed $border-color;
    }
    .pt {
        padding-top: 80px;
    }
}
</style>
