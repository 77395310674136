<!-- 规则 公共组件 -->
<template>
    <a-table
        :columns="columns"
        :pagination="false"
        :loading="loading"
        :data-source="list"
        row-key="id">
        <template slot="state" slot-scope="val">
            <span v-if="!!val">符合</span>
            <span v-else style="color: red">不符合</span>
        </template>
    </a-table>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'

const columns = [
    {
        title: '序号',
        key: 'sort',
        align: 'left',
        width: 200,
        customRender: (v, r, i) => ++i
    },
    {
        title: '规则名称',
        dataIndex: 'ruleName',
        align: 'left',
        key: 'ruleName',
        width: 300,
        ellipsis: true
    },
    {
        title: '执行结果',
        dataIndex: 'state',
        align: 'left',
        key: 'state',
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        title: '实际规则结果',
        dataIndex: 'actualValue',
        align: 'left',
        key: 'actualValue',
        ellipsis: true
    },
    {
        title: '更新时间',
        dataIndex: 'createDateTime',
        align: 'left',
        key: 'createDateTime'
    }
]
export default {
    name: 'BaseRuleTable',
    components: {
        ATable
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            columns,
            loading: false,
            data: []
        }
    },
    computed: {},
    methods: {}
}
</script>
