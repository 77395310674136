<!-- 承保信息 -->
<template>
    <page-layout
        v-if="dataInfo"
        :show-header="false"
        :show-loading="true"
        :loading="loading"
        class="risk-assess-info-panel">
        <container-panel>
            <template v-for="item in columns">
                <info-panel
                    v-if="columns && columns.length>0"
                    :key="item.key"
                    :title="item.title"
                    :columns="item.columns"
                    :like-table="item.likeTable"
                    :is-insurance-policy="item.isInsurancePolicy"
                    :record="item.record || {}"
                />
            </template>
            <!-- 承保信息 -->
            <a-table
                v-if="underwriteData.length"
                :columns="underwriteColumns"
                :data-source="underwriteData"
                :loading="loading"
                :pagination="false"
                :scroll="{
                    x: 1100,
                    y: 500
                }"
                row-key="id"
                style="margin-top: 30px" />
        </container-panel>
    </page-layout>
</template>

<script>
import { Table } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import PageLayout from '@layout/PageLayout'

import { INSURE_TYPE_ENUM, UNDERWRITE_MAP } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'
import { underwritingInfoColumns, insurancePolicyColumns } from '@views/RiskControlProcurement/OrderInfo/mixins'

import '@views/RiskControlProcurement/OrderInfo/style.scss'

import getInsureInfoService from '@service/order/getInsureInfoService'

const underwriteColumns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '保司名称',
        dataIndex: 'insuranceCompanyName',
        align: 'left',
        width: 200,
        key: 'insuranceCompanyName'
    },
    {
        title: '风控报告类型',
        dataIndex: 'reportTypeName',
        align: 'left',
        width: 200,
        key: 'reportTypeName'
    },
    {
        title: '承保结果',
        dataIndex: 'underwritingResult',
        align: 'left',
        width: 200,
        key: 'underwritingResult',
        customRender: v => UNDERWRITE_MAP[v]
    },
    {
        title: '反馈时间',
        dataIndex: 'feedbackDataTime',
        align: 'left',
        width: 200,
        key: 'feedbackDataTime'
    }
]
export default {
    name: 'InsureInfoPanel',
    components: {
        ATable: Table,
        PageLayout,
        ContainerPanel,
        InfoPanel: () => import('@views/RiskControlProcurement/OrderInfo/InfoPanel')
    },
    mixins: [enumOperate],
    data() {
        return {
            INSURE_TYPE_ENUM,
            resObj: {},
            dataInfo: null,
            activeKey: '0',
            componentsList: [],
            underwriteData: []
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        // 字段
        columns() {
            const { underwritingResult, formList = [] } = this.dataInfo
            return [
                {
                    key: 105,
                    title: '承保信息',
                    likeTable: true,
                    columns: underwritingInfoColumns[underwritingResult],
                    record: this.dataInfo
                }, ...(formList || []).map((item, index) => ({ // 保单列表
                    key: item.id,
                    title: `保单${index + 1}`,
                    likeTable: true,
                    isInsurancePolicy: true,
                    columns: insurancePolicyColumns,
                    record: item || {}
                }))
            ]
        },
        underwriteColumns() {
            return underwriteColumns
        }
    },
    created() {
        this.getData()
    },
    methods: {
        /**
         * tab改变
         */
        onTabChange(value) {
            this.activeKey = value.target.value
        },
        async getData() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.dataInfo = await getInsureInfoService({
                    riskControlNumber
                })
                this.underwriteData = this.dataInfo.insuranceResults || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.risk-assess-info-panel {
    .ant-descriptions-item-label {
        width: $descriptions-item-label-width;
    }

    .page-layout__content {
        padding: 0 16px 16px 16px;
    }
}
</style>
