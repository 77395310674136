<!-- 关联订单 -->
<template>
    <table-panel class="relevance-order-page-panel">
        <div slot="title">关联订单</div>
        <a-table
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="associatedOrderList"
            row-key="id">
            <template slot="sort" slot-scope="text, record,index">{{ index+1 }}</template>
        </a-table>
    </table-panel>
</template>

<script>
import TablePanel from '@components/TablePanel'
import { Table as ATable } from 'ant-design-vue'
const columns = [
    {
        title: '序号',
        key: 'sort',
        scopedSlots: {
            customRender: 'sort'
        },
        align: 'center'
    },
    {
        title: '关联订单号',
        dataIndex: 'associatedOrderNumber',
        align: 'left',
        key: 'associatedOrderNumber'
    },
    {
        title: '保险机构',
        dataIndex: 'insuranceInstitutions',
        align: 'left',
        key: 'insuranceInstitutions'
    },
    {
        title: '提交时间',
        dataIndex: 'submissionTime',
        align: 'left',
        key: 'submissionTime'
    }
]
export default {
    name: 'RelevanceOrderPagePanel',
    components: {
        TablePanel,
        ATable
    },
    props: {
        associatedOrderList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            columns,
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
</style>
