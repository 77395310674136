<!-- 选择风险等级模型
  依赖业务类型来切换数据 -->
<template>
    <a-select
        v-model="modelValue"
        placeholder="请选择"
        class="select-option-label-prop"
        option-label-prop="label">
        <a-select-option
            v-for="item in dataList"
            :key="item.id"
            :value="item.id"
            :label="item.modelExamplesName">
            {{ item.modelExamplesName }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'

import { DATA_STATE_TYPE_USED } from '@constant/enum'

import getRiskClassAllListService from '@service/ruleEngine/riskClass/getRiskClassAllListService'

export default {
    name: 'RiskClassModel',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [String, Number]
        },
        riskBusinessType: {
            type: [String, Number]
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {
        riskBusinessType: {
            handler(val) {
                if (!val) return
                this.getList()
            },
            immediate: true
        }
    },
    methods: {
        async getList() {
            const { riskBusinessType } = this
            try {
                this.dataList = await getRiskClassAllListService({
                    riskBusinessType,
                    state: DATA_STATE_TYPE_USED
                })
                console.log(this.dataList)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
