<!-- 保司自有快速核保规则 -->
<template>
    <table-panel class="risk-assessment-page-panel">
        <div slot="title">{{ BAOSI_ZIYOU_KUAISU_HEBAO }}</div>
        <rule-set-table
            :get-list-service="service"
        />
    </table-panel>
</template>

<script>
import TablePanel from '@components/TablePanel'

import service from '@service/task/getOrderInsureRuleListService'
import { COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_LABEL as BAOSI_ZIYOU_KUAISU_HEBAO } from '@constant/enum'

export default {
    name: 'InsureRule',
    components: {
        TablePanel,
        RuleSetTable: () => import('../common/RuleSetTable')
    },
    data() {
        return {
            BAOSI_ZIYOU_KUAISU_HEBAO,
            service
        }
    }
}
</script>
