<template>
    <!-- 查看意见弹窗 -->
    <a-modal
        :visible="visible"
        title="查看意见"
        :footer="null"
        :width="540"
        @cancel="handleCancel">
        <a-form-model
            layout="horizontal"
            :label-col="{ span:6}"
            :wrapper-col="{ span: 18 }">
            <a-form-model-item label="操作内容" prop="name">
                <div>{{ info.evaluationOpinion }}</div>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { FormModel as AFormModel } from 'ant-design-vue'

export default {
    name: 'BlackListModal',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        // 显示隐藏
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.$emit('close-model')
        }
    }
}
</script>

<style lang="scss">
.black-list-modal {
  &__title {
    margin-bottom: 15px;
    font-family: PingFangSC-Light, PingFang SC;
    color: #000;
  }

  &__table {
    margin-bottom: 50px;
  }
}
</style>
