<template>
    <!-- 黑名单查看 -->
    <a-modal
        :visible="visible"
        title="查看"
        class="black-list-modal"
        :footer="null"
        :width="800"
        @cancel="handleOk"
    >
        <div class="black-list-modal__title">{{ info.businessObjectName }}</div>
        <a-table
            class="black-list-modal__table"
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="dataList"
            :scroll="{
                x: 800
            }"
            row-key="id" />
    </a-modal>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'
import getBlackListOrWarningInfoService from '@service/order/getBlackListOrWarningInfoService'

export default {
    name: 'BlackListModal',
    components: {
        ATable
    },
    props: {
    // 黑名单记录数据id
        id: {
            type: String,
            default: ''
        },
        // 显示隐藏
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            columns: [],
            dataList: [],
            info: {}
        }
    },
    watch: {
        id(val) {
            this.getInfo(val)
        }
    },
    methods: {
        handleOk() {
            this.$emit('close-model')
        },
        async getInfo(id) {
            try {
                this.loading = true
                const res = await getBlackListOrWarningInfoService({
                    id
                })
                this.info = res
                const { businessObjectDefinition, externalResults } = res
                const parseDefinition = JSON.parse(businessObjectDefinition)
                const parseResult = JSON.parse(externalResults)
                const { businessObjectFieldList } = parseDefinition
                businessObjectFieldList.forEach(item => {
                    const { fieldName, fieldCode } = item.field
                    this.columns.push({
                        ...item.field,
                        title: fieldName,
                        dataIndex: fieldCode,
                        key: fieldCode,
                        width: 200
                    })
                })
                this.dataList = parseResult
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }

}
</script>

<style lang="scss">
.black-list-modal {
  &__title {
    margin-bottom: 15px;
    font-family: PingFangSC-Light, PingFang SC;
    color: #000;
  }

  &__table {
    margin-bottom: 50px;
  }
}
</style>
