<!-- 提交时间 区间范围选择 -->
<script>

import { DatePicker as ADatePicker } from 'ant-design-vue'
import moment from 'moment'

export default {
    name: 'SubmitTimeSection',
    components: {
        ARangePicker: ADatePicker.RangePicker
    },
    props: {
        value: {
            type: Array,
            default() {
                return undefined
            }
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        disableTime: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            timePickerMode: ['date', 'date']
        }
    },
    methods: {
        disabledDate(current) {
            return current && current > moment().endOf('day')
        },
        handleTimeChange(moment, stringTime) {
            this.$emit('input', stringTime)
            this.$emit('time', stringTime)
        },
        onBlur() {
            this.$emit('blur')
        }
    },
    render(h) {
        const {
            timePickerMode, format, disableTime,
            disableDate, handleTimeChange, onBlur, value
        } = this
        const props = {
            value,
            mode: timePickerMode,
            format
        }
        if (disableTime) {
            props.disableDate = disableDate
        }
        return h('ARangePicker', {
            props,
            on: {
                change: handleTimeChange,
                blur: onBlur
            }
        })
    }
}
</script>

<style lang='scss'>

</style>
