<!-- 风险评分评级/特征工程评分等级 -->
<template>
    <table-panel
        v-if="dataList.length"
        class="risk-rating-page-panel">
        <div slot="title">
            {{ title }}
            <span class="risk-rating-page-panel__number">
                {{ titleDesc }}
            </span>
        </div>
        <a-table
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="dataList"
            row-key="id">
            <template slot="score" slot-scope="text, record">
                {{ text }}-{{ record.riskLevel }}
            </template>
        </a-table>
    </table-panel>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'
import TablePanel from '@components/TablePanel'
import getRiskOrderRatingListService from '@service/order/getRiskOrderRatingListService'
import { DEFAULT_PAGES_SIZE } from '@config'
import tableShowTotal from '@mixins/tableShowTotal'

const columns = [
    {
        title: '序号',
        scopedSlots: {
            customRender: 'sort'
        },
        align: 'left',
        width: 200,
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '评分评级',
        dataIndex: 'ratingScore',
        align: 'left',
        key: 'ratingScore'
    },
    {
        title: '操作人',
        dataIndex: 'operatorName',
        align: 'left',
        key: 'operatorName'
    },
    {
        title: '更新时间',
        dataIndex: 'createDateTime',
        align: 'left',
        key: 'createDateTime'
    }
]
export default {
    name: 'RiskRatingPagePanel',
    components: {
        TablePanel,
        ATable
    },
    mixins: [tableShowTotal],
    props: {
        // 风险评分评级 1
        // 特征工程评分等级 2
        ratingType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            columns,
            loading: false,
            dataList: []
        }
    },
    computed: {
        title() {
            return this.ratingType === 1 ? '风险评分评级' : '特征工程评分等级'
        },
        pagination() {
            const { total, size, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        // 标题的描述等级
        titleDesc() {
            const { dataList } = this
            const len = dataList.length
            if (!len) return ''
            const lastOne = dataList[len - 1]
            return `（${lastOne.score} ${lastOne.riskLevelDesc}）`
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async getList() {
            try {
                this.loading = true
                const { size, current, riskControlNumber, ratingType } = this
                const res = await getRiskOrderRatingListService({
                    size,
                    current,
                    riskControlNumber,
                    ratingType
                })
                this.dataList = res || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.risk-rating-page-panel {
  &__number {
    color: #f00;
  }
}
</style>
