<template>
    <a-cascader
        v-model="model"
        change-on-select
        :options="options"
        placeholder="请选择"
        @change="handleChange" />
</template>

<script>
import getProductCategoryTreeService from '@service/commodityManage/productCategory/getProductCategoryTreeService'
import { getParentIds, getMap, recursionToTree } from '@utils/toTree'

import { PRODUCT_CATEGORY_TREE_ENABLE_OPEN } from '@constant/enum'
import {
    Cascader as ACascader
} from 'ant-design-vue'

export default {
    name: 'ProductCategoryCascader',
    components: {
        ACascader
    },
    props: {
        value: {
            type: String
        },
        enable: {
            type: Number,
            default() {
                return PRODUCT_CATEGORY_TREE_ENABLE_OPEN
            }
        }
    },
    data() {
        return {
            options: [],
            map: null,
            modelValue: []
        }
    },
    computed: {
        model: {
            get() {
                const { map, value } = this
                if (map && value) {
                    return getParentIds(this.map, value)
                }
                return []
            },
            set(value) {
                this.$emit('blur')
                this.$emit('input', value)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
    // 获取分类的列表
        async getList() {
            const { enable } = this
            try {
                this.options = recursionToTree(await getProductCategoryTreeService({
                    enable
                }))
                // 树形转map
                this.map = getMap(this.options)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleChange(value) {
            const len = value.length
            this.model = len ? value[len - 1] : ''
        }
    }
}
</script>

<style lang='scss'>

</style>
