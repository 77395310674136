<!-- 评分模型列表 -->
<template>
    <a-select
        v-model="modelValue"
        allow-clear
        :mode="mode"
        placeholder="请选择">
        <a-select-option
            v-for="item in dataList"
            :key="item.id"
            :value="item.id">
            {{ item.modelExamplesName }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'

import getGradeModelListService from '@service/ruleEngine/GradeModel/getGradeModelListService'
import { DATA_STATE_TYPE_USED } from '@constant/enum'

export default {
    name: 'GradeModelSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [Array, String]
        },
        mode: {
            type: String,
            default: 'default'
        },
        scoringModelType: {
            type: Number
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
                this.$emit('blur')
            }
        }
    },
    created() {
        this.getSysDictList()
    },
    methods: {
        // 保司列表
        async getSysDictList() {
            try {
                const { scoringModelType } = this
                const params = {
                    current: 1,
                    size: 99,
                    state: DATA_STATE_TYPE_USED
                }
                if (scoringModelType) { // 筛选评分卡
                    params.scoringModelType = scoringModelType
                }
                const { data } = await getGradeModelListService(params)
                this.dataList = data.records
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
