<!-- 承保 - 基本信息表单 -->
<template>
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
    >
        <!-- 是否承保 -->
        <a-form-model-item
            label="是否承保"
            prop="underwritingResult"
        >
            <a-select
                v-model="form.underwritingResult"
                @change="clearValidate"
            >
                <a-select-option
                    v-for="({ label, value }) in options"
                    :key="value"
                    :value="value"
                >
                    {{ label }}
                </a-select-option>
            </a-select>
        </a-form-model-item>

        <!-- 是否承保 选择 是 -->
        <template
            v-if="
                RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE ===
                    form.underwritingResult
            "
        >
            <!-- 承保保险公司 -->
            <a-form-model-item
                label="承保保险公司"
                prop="insuranceCompany"
            >
                <a-input
                    v-model="form.insuranceCompany"
                    placeholder="请输入承保保险公司"
                />
            </a-form-model-item>

            <!-- 保险金额 -->
            <a-form-model-item
                label="保险金额"
                prop="insuranceAmount"
            >
                <a-input-number
                    v-model="form.insuranceAmount"
                    placeholder="请输入保险金额"
                    style="width: 100%;"
                />
            </a-form-model-item>
        </template>

        <!-- 是否承保 选择 否 -->
        <template v-else>
            <!-- 拒保原因 -->
            <a-form-model-item
                label="拒保原因"
                prop="noInsuranceDesc"
            >
                <a-textarea
                    v-model="form.noInsuranceDesc"
                    :auto-size="textareaAutosize"
                    placeholder="请输入拒保原因"
                />
            </a-form-model-item>
        </template>
    </a-form-model>
</template>
<script>
// 组件
import {
    FormModel,
    Select,
    Input,
    InputNumber
} from 'ant-design-vue'

import {
    RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE,
    RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_ENUM_LIST
} from '@constant/enum'

export default {
    name: 'BaseInfoFormPanel',
    components: {
        AFormModel: FormModel,
        AFormModelItem: FormModel.Item,
        ASelect: Select,
        ASelectOption: Select.Option,
        AInput: Input,
        AInputNumber: InputNumber,
        ATextarea: Input.TextArea
    },
    data() {
        return {
            RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE,
            // 是否承保（选项）
            options: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_ENUM_LIST,

            // 表单数据
            form: {
                underwritingResult: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE
            },
            // 表单验证规则
            rules: {
                insuranceCompany: [
                    {
                        required: true,
                        message: '请输入承保保险公司'
                    }
                ],
                insuranceAmount: [
                    {
                        required: true,
                        message: '请输入保险金额'
                    }
                ],
                noInsuranceDesc: [
                    {
                        required: true,
                        message: '请输入拒保原因'
                    }
                ]
            },

            // a-textarea autosize
            textareaAutosize: {
                minRows: 3,
                maxRows: 6
            }
        }
    },
    mounted() {
        this.emitChange()
    },
    methods: {
        emitChange() {
            this.$emit('change', this.form)
        },
        clearValidate() {
            this.$refs.form.clearValidate()
            this.emitChange()
        },
        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
