<!-- 规则集分类 -->
<template>
    <a-select
        v-model="modelValue"
        allow-clear
        placeholder="请选择规则集分类"
        @change="handleChange">
        <a-select-option
            v-for="item in RULE_SET_TYPE_ENUM_LIST"
            :key="item.value"
            :value="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import {
    RULE_SET_TYPE_ENUM_LIST
} from '@constant/enum'

export default {
    name: 'RuleSetType',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [String, Number]
        }
    },
    data() {
        return {
            RULE_SET_TYPE_ENUM_LIST
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
                this.$emit('blur')
            }
        }
    },
    methods: {
        handleChange() {
            this.$emit('change')
        }
    }
}
</script>

<style lang='scss'>

</style>
