<!-- 自动核保规则 点击查看 弹窗 -->
<template>
    <custom-modal
        :title="title"
        :visible="visible"
        :mask-closable="false"
        :width="1250"
        style="overflow: scroll"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
        </template>
        <base-rule-table :list="info.roBasicRuleExecutionResults" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

export default {
    name: 'BaseRuleModal',
    components: {
        AButton,
        CustomModal,
        BaseRuleTable: () => import('@weights/TaskAndOrderCom/common/BaseRuleTable')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    computed: {
        title() {
            return this.info.ruleSetName
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style lang='scss'>

</style>
