<!-- 保司列表 -->
<template>
    <div style="display: flex">
        <a-select
            v-model="modelValue"
            allow-clear
            :mode="mode"
            placeholder="请选择保司">
            <a-select-option
                v-for="item in dataList"
                :key="item[code]"
                :value="item[code]">
                {{ item.dataValue }}
            </a-select-option>
        </a-select>
        <a-button
            v-if="showSelectAll"
            style="margin-left: 8px"
            type="primary"
            @click="handleAll">
            {{ selectAllName }}
        </a-button>
    </div>
</template>

<script>
import getSysDictListService from '@service/getSysDictListService'
import { getMapFromArr } from '@utils'
import { Select as ASelect, Button as AButton } from 'ant-design-vue'

export default {
    name: 'SysList',
    components: {
        AButton,
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [Array, String]
        },
        code: {
            type: String,
            default: 'dataCode'
        },
        mode: {
            type: String,
            default: 'default'
        },
        showSelectAll: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        isSelectAll() {
            const { modelValue, dataList } = this
            return modelValue.length === dataList.length
        },
        selectAllName() {
            return this.isSelectAll ? '取消全选' : '选择全部'
        },
        sysMap() {
            const { dataList, code } = this
            return getMapFromArr(dataList, code)
        },
        modelValue: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
                this.$emit('blur')
            }
        }
    },
    created() {
        this.getSysDictList()
    },
    methods: {
        handleAll() {
            const { isSelectAll, dataList, code } = this
            if (isSelectAll) {
                this.modelValue = []
            } else {
                this.modelValue = dataList.map(item => item[code])
            }
        },
        // 保司列表
        async getSysDictList() {
            try {
                this.dataList = await getSysDictListService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
