<template>
    <a-modal
        :visible="visible"
        :title="`查看${info.name}`"
        class="look-log-list-modal"
        :footer="null"
        :width="800"
        @cancel="handleCancel">
        <div v-if="info.value" class="look-log-list-modal__container">
            <div class="old">
                <h1>old</h1>
                <pre>{{ info.value.old }}</pre>
            </div>
            <div class="new">
                <h1>new</h1>
                <pre>{{ info.value.new }}</pre>
            </div>
        </div>
    </a-modal>
</template>

<script>

export default {
    name: 'BlackListModal',
    components: {},
    props: {
    // 显示隐藏
        visible: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            columns: [],
            dataList: []
        }
    },
    created() {
        console.log(this.info)
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        }
    }

}
</script>

<style lang="scss">
.look-log-list-modal {
  &__container {
    display: flex;
    justify-content: space-around;
    h1 {
      text-align: center;
    }
    >div {
      width: 45%;
      border: 1px solid #ddd;
    }
  }
}
</style>
